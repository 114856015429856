import React, { useState } from "react";
import { Col, Row } from "antd";
import hotelNoImg from "../../../assets/images/hotels/no_photo.png";
import Table from "react-bootstrap/Table";
import QRCode from "qrcode.react";
import "../ticketActivities.scss";
import moment from "moment";
import { useAuthContext } from "../../providers/AuthProvider";
import { useCurrencyContext } from "../../providers/CurrencyProvider"
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  QrcodeOutlined,
  BellOutlined,
  CloseCircleOutlined,
  StopOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";

const TicketBuildPackage = ({ ticketData }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [CancellationPolicyModal, setCancellationPolicyModal] = useState(false);
  const [CancellationPolicy, setCancellationPolicy] = useState([]);
  const getHotelStatus = (status) => {
    switch (status) {
      case 2: {
        return (
          <span style={{ color: "#008000" }}>
            <b> CONFIRMED</b>
          </span>
        );
      }

      case 1: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> FAILED</b>
          </span>
        );
      }
      case 3: {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> CANCELLED</b>
          </span>
        );
      }
      default:
        return;
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 0: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> CANCELLED <CloseCircleOutlined /></b>
          </span>
        );
      }
      case 1: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> ALREADY CANCELLED <CloseCircleOutlined /></b>
          </span>
        );
      }
      case 2: {
        return (
          <span style={{ color: "#FFA500" }} className="border p-2 rounded">
            <b>BLOCKED <StopOutlined /></b>
          </span>
        )
      }
      case 3: {
        return (
          <span style={{ color: "#008000" }} className="border p-2 rounded">
            <b> CONFIRMED <CheckCircleOutlined /></b>
          </span>
        );
      }
      case 7: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> On Request <BellOutlined /> </b>
          </span>
        );
      }
      case 11: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> VOUCHERED <QrcodeOutlined /></b>
          </span>
        );
      }
      default:
        return;
    }
  };

  const getPaxCount = (pax) => {
    return `Adult(s) ${pax?.adult !== "0" ? pax?.adult : 0} ${pax?.child !== "0" ? ", Child(s) " + pax?.child : " "
      } ${pax?.infant !== "0" ? ", Infant(s) " + pax?.infant : " "}`;
  };

  const getTicketPolicy = (optionData) => {
    let { tourId, journeyDate, startTime, serviceUniqueId, optionId } = optionData;
    setCancellationPolicyModal(true)
    setCancellationPolicy(optionData?.ticketPolicy)
  }

  const getTimebyUser = (optionData) => {
    if (
      (optionData.optionName.includes('Airport') ||
        optionData.optionName.includes('Transfer') &&
        optionData.transferName === "Private Transfers")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="activities-ticket">
      <div className="activities-details">
        <ul className="tour-timeline tour-timeline-left">
          <div className="tour-details-panel">
            <h6 className="pax-title">Passanger Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    <UserOutlined />
                    Guest Name:{" "}
                    <span>
                      {ticketData?.leadPassenger?.firstName}{" "}
                      {ticketData?.leadPassenger?.lastName}
                    </span>
                  </p>
                </Col>

                <Col md={12} sm={12} xs={24}>
                  <p>
                    <MailOutlined />
                    Email: <span>{ticketData?.leadPassenger?.email}</span>
                  </p>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    <PhoneOutlined />
                    Contact: <span>{ticketData?.leadPassenger?.mobile}</span>
                  </p>
                </Col>
              </Row>
            </div>
            <h6 className="pax-title">Payment Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Confirmation Number:{" "}
                    <span>{ticketData?.bookingRefNo}</span>
                  </p>
                </Col>

                <Col md={12} sm={12} xs={24}>
                  <p>
                    Total Fare:
                    <span> {activeCurrency} {currencyValue(ticketData?.partPayment?.serviceTotal)}</span>
                  </p>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Payment Mode:
                    <span>
                      {ticketData?.partPayment?.isPartPayment === true
                        ? " Part"
                        : " Full"}{" "}
                      Payment
                    </span>
                  </p>
                </Col>

                {ticketData?.partPayment?.isPartPayment === true ? (
                  <>
                    <Col md={12} sm={12} xs={24}>
                      <p>
                        Paid Amount:
                        <span>
                          {activeCurrency} {currencyValue(ticketData?.partPayment?.partPaymentAmount)}{" "}
                        </span>
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <p className="text-danger">
                        Due Amount:
                        <span> {activeCurrency} {currencyValue(ticketData?.partPayment?.dueAmount)} </span>
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <p className="text-danger">
                        Last Date:
                        <span> {ticketData?.partPayment?.PaymentDueDate} </span>
                      </p>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>

          <Row>
            <Col md={24} xs={24}>
              <ul className="timeline timeline-left">
                <Row className="tours-book-method">
                  <Col md={24} xs={24}>
                    <h4 className="hotel-room-info">Hotel</h4>
                  </Col>
                </Row>
                {ticketData.hotelsPackageDetails.length > 0
                  ? ticketData.hotelsPackageDetails.map((hotel, index) => {
                    return (
                      <li className="timeline-inverted timeline-item">
                        <div className="timeline-badge warning">
                          <span className="font-12">
                            <i
                              className="fa fa-hospital-o"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                        <div className="timeline-panel">
                          <div className="timeline-body">
                            <div className="info-hotel-main p-2">
                              <Row>
                                <Col md={6} xs={24}>
                                  {hotel.hotelImage ? (
                                    <img
                                      className="img-cotation-total"
                                      alt="example"
                                      src={hotel.hotelImage}
                                    />
                                  ) : (
                                    <img
                                      className="img-cotation-total"
                                      src={hotelNoImg}
                                      alt="no-photo"
                                    />
                                  )}
                                </Col>
                                <Col
                                  md={18}
                                  xs={24}
                                  className="bty-info-hote"
                                >
                                  <h5 className="review-name-hotel">
                                    {hotel.HotelName}
                                  </h5>
                                  <ul className="time-and-date-main">
                                    <li>
                                      <i
                                        className="fa fa-hospital-o"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp; {hotel.HotelAddress}
                                    </li>
                                  </ul>
                                  <ul className="last-date-maion">
                                    <li className="fn-bold">
                                      Check In Date:{" "}
                                      {moment(hotel.CheckInDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </li>

                                    <li className="fn-bold">
                                      Check Out Date :{" "}
                                      {moment(hotel.CheckOutDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </li>
                                    <li className="fn-bold">
                                      No Of Night: {hotel.Rooms[0].noOfNight}
                                    </li>
                                  </ul>

                                  <ul className="last-date-maion">
                                    <li className="fn-bold">
                                      Total: {activeCurrency} {currencyValue(hotel.Fare)}
                                    </li>
                                  </ul>

                                  <ul className="last-date-maion">
                                    <li className="fn-bold">
                                      Booking Status :{" "}
                                      {getHotelStatus(hotel?.Status)}
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                              <Table
                                className="mt-2"
                                responsive="lg"
                                striped
                                bordered
                                hover
                              >
                                <thead>
                                  <tr>
                                    <th>Room Type</th>
                                    <th>Unit</th>
                                    <th>Room Cost </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {hotel.Rooms.map((room) => {
                                    return (
                                      <tr>
                                        <td>{room.RoomName}</td>
                                        <td>{room.noOfNight}</td>
                                        <td>₹ {room.pricePerRoom}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                  : null}

                <Row className="tours-book-method">
                  <Col md={24} xs={24}>
                    <h4 className="hotel-room-info">Tours</h4>
                  </Col>
                </Row>

                {ticketData.tourDetails.length > 0
                  ? ticketData.tourDetails.map(
                    (tour, index) =>
                      tour &&
                      tour?.length > 0 &&
                      tour.map((optionData) => (
                        <li className="timeline-inverted timeline-item">
                          <div className="timeline-badge warning">
                            <p className="mb-0">Day {index + 1}</p>
                          </div>
                          <div className="timeline-panel ">
                            <div className="timeline-body">
                              <h6 className="font-weight-bold">
                                {optionData.tourName}
                              </h6>

                              {/* <p className="wt-activity3">
                                  {moment(
                                    optionData.tourDate,
                                    "YYYY-MM-DD"
                                  ).format("DD-MM-YYYY")}
                                </p> */}

                              <p className="subtitle">{optionData.optionName}</p> <br></br>
                              <div className="tour-timeline-body">
                                {!agent && (
                                  <p className="font-weight-bold mb-3">
                                    Provider Reference No :{" "}
                                    {optionData?.ProviderRefNo}
                                  </p>
                                )}

                                {optionData?.confirmationNo && (
                                  <p className="font-weight-bold mb-3">
                                    Confirmation No: :{" "}
                                    {optionData?.confirmationNo}
                                  </p>
                                )}

                                <Row className="justify-content-between">
                                  <Col md={6} xs={24}>
                                    <img
                                      className="img-fluid "
                                      alt="example"
                                      src={optionData.tourImage}
                                    />
                                  </Col>

                                  {!(optionData?.tourId?.startsWith('EXT') || optionData?.tourId?.startsWith('Ext'))
                                    && optionData?.transferName !== "Private Transfers"
                                    && optionData?.BookingStatus !== 11 && (
                                      <Col md={8} xs={24} style={{ position: 'relative', textAlign: 'center' }}>
                                        <QrcodeOutlined style={{ fontSize: '140px', filter: 'blur(3px)', opacity: '0.2' }} />
                                        <span style={{
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          fontSize: '14px',
                                          color: 'black',
                                          textShadow: '1px 1px 2px rgba(248, 248, 247, 0.2)' // Slight shadow for better readability
                                        }}>
                                          QR Code not Generated
                                        </span>
                                      </Col>
                                    )}
                                </Row>

                                <Row className="justify-content-between pr-3" gutter={[2, 2]}>
                                  {optionData?.ticket?.length > 0 ? null : (
                                    (optionData?.BookingStatus === 11 && optionData?.transferName !== "Private Transfers") &&
                                      (!(optionData?.tourId?.startsWith('EXT') || optionData?.tourId?.startsWith('Ext'))) ? (
                                      <div class="alert" role="alert" style={{ background: "linear-gradient(6deg, #f1e67b, transparent)", width: '100%' }}>
                                        <p className="font-weight-bold m-0"><span className="text-danger">Note :-</span>Please wait for 30 minutes for the availability of the QR code. If any query contact on <strong>Emergency Number :- 9166868837, 9999442804, 011-49511313</strong>. Thank you for your patience.</p>
                                      </div>
                                    ) : null
                                  )}

                                  {(optionData?.tourId?.startsWith('EXT') || optionData?.tourId?.startsWith('Ext'))
                                    && optionData?.transferName === "Private Transfers"
                                    && optionData?.BookingStatus !== 11 ? (
                                    <div class="alert" role="alert" style={{ background: "linear-gradient(6deg, #f1e67b, transparent)", width: '100%' }}>
                                      <p className="font-weight-bold m-0"><span className="text-danger">Note :-</span>Don't forget to voucher your transfer. If you do not voucher this transfer, it will be cancelled automatically.</p>
                                    </div>
                                  ) : (
                                    optionData?.BookingStatus == 3 && (
                                      // <Col className="wt-activity" md={24} xs={24}>
                                      //   <p style={{ backgroundColor: '#fcf6c5', padding: '1px', marginBottom: '-2px' }}>
                                      //     <p><strong style={{ color: 'red' }}>Note :- </strong>Please do vouchered your ticket.</p>
                                      //   </p>
                                      // </Col>
                                      <div class="alert" role="alert" style={{ background: "linear-gradient(6deg, #f1e67b, transparent)", width: '100%' }}>
                                        <p className="font-weight-bold m-0"><span className="text-danger">Note :-</span>Please do vouchered your ticket.</p>
                                      </div>
                                    )
                                  )}

                                  {(!(optionData.tourId?.startsWith('EXT') || optionData.tourId?.startsWith('Ext')) && optionData.transferName !== "Private Transfers") && (
                                    // <Col md={24} xs={24}>
                                    <div className="alert alert-info">
                                      <p
                                        className="hotel-list-para"
                                        dangerouslySetInnerHTML={{
                                          __html: optionData.tourShortDescription,
                                        }}
                                      ></p>
                                    </div>
                                    // </Col>
                                  )}

                                  <table class="table">
                                    <tbody>
                                      <tr>
                                        <td><strong>Booking Status</strong></td>
                                        <td>{getStatus(optionData.BookingStatus)}</td>
                                      </tr>

                                      <tr>
                                        <td><strong> Booking ID :{" "}</strong></td>
                                        <td> {optionData?.BookingRefNo}</td>
                                      </tr>

                                      <tr>
                                        <td><strong>Travel Date :{" "}</strong></td>
                                        <td>  {moment(
                                          optionData.tourDate,
                                          "YYYY-MM-DD"
                                        ).format("DD-MM-YYYY")}</td>
                                      </tr>

                                      {((optionData.tourId?.startsWith('EXT') || optionData.tourId?.startsWith('Ext')) && (optionData.BookingStatus === 3)) ? (
                                        <tr>

                                          <td><strong>Last Vouchered Date :</strong></td>
                                          <td>
                                            {moment(optionData.tourDate, "YYYY-MM-DD").subtract(4, 'days').format("ddd DD MMM YYYY")}
                                          </td>
                                        </tr>
                                      ) : null}

                                      {((optionData.tourId?.startsWith('EXT') || optionData.tourId?.startsWith('Ext')) && (optionData.BookingStatus === 11)) ? (
                                        <tr>

                                          <td><strong>Last Cancellation Date :</strong></td>
                                          <td>
                                            {moment(optionData.tourDate, "YYYY-MM-DD").subtract(4, 'days').format("ddd DD MMM YYYY")}
                                          </td>
                                        </tr>
                                      ) : null}

                                      <tr>
                                        <td><strong> Transfer Option :{" "}</strong></td>
                                        <td>{optionData.transferName}</td>
                                      </tr>

                                      {!getTimebyUser(optionData) && optionData.duration && (
                                        <tr>
                                          <td><strong>Duration :{" "}</strong></td>
                                          <td>
                                            {optionData.duration}
                                          </td>
                                        </tr>
                                      )}

                                      {optionData?.ticket?.length > 0 && optionData?.ticket.some(ticket => ticket?.timeSlot) ? (
                                        <tr>
                                          <td>
                                            <strong>Slot :</strong>
                                          </td>
                                          <td>
                                            {optionData?.ticket
                                              .filter(ticket => ticket?.timeSlot) // Filter out undefined or empty timeSlot values
                                              .map(ticket => ticket.timeSlot)
                                              .join(', ')}
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td>
                                            <strong >{optionData.transferName === "Private Transfers" ? "Pickup Time" : "Start Time"} :</strong>
                                          </td>
                                          <td>
                                            {optionData ? optionData.startTime : 'Time Not Available'}
                                          </td>
                                        </tr>
                                      )}

                                      {optionData.pickup !== "" && (
                                        <tr>
                                          <td><strong>{optionData.tourName.includes("Pick Up") ? "Drop Off Location" : "Pick Up Location"}</strong></td>
                                          <td>
                                          {optionData.pickup}
                                          </td>
                                        </tr>
                                      )}

                                      <tr>
                                        <td><strong>Number of Passengers :{" "}</strong></td>
                                        <td>
                                          {getPaxCount(optionData)}
                                        </td>
                                      </tr>

                                      {!(optionData?.TransferObj == undefined || Object.keys(tour?.TransferObj).length == 0) && optionData?.TransferObj?.FlightName !== null && optionData?.TransferObj?.FlightNo !== null && (<tr>
                                        <td>
                                          <strong>Check-In Luggage :</strong>
                                        </td>
                                        <td>
                                          23 Kg/per X {optionData?.TransferObj?.CheckLuggages} Pax
                                        </td>
                                        <td>
                                          <strong>Hand Luggage :</strong>
                                        </td>
                                        <td>
                                          7 Kg/per X {optionData?.TransferObj?.Handluggage} Pax
                                        </td>
                                        <td>
                                          <strong>Flight/Train Name :</strong>
                                        </td>
                                        <td>
                                          {optionData?.TransferObj?.FlightName}
                                        </td>
                                        <td>
                                          <strong>Flight/Train Number :</strong>
                                        </td>
                                        <td>
                                          {optionData?.TransferObj?.FlightNo}
                                        </td>
                                      </tr>)}

                                      <tr>
                                        <td><strong>Amount :{" "}</strong></td>
                                        <td>
                                          {activeCurrency} {currencyValue(optionData.serviceTotal)}
                                        </td>
                                      </tr>

                                      {!(optionData?.tourId?.startsWith('EXT') || optionData?.tourId?.startsWith('Ext'))
                                        && optionData?.transferName !== "Private Transfers" && (
                                          <tr>
                                            <td>
                                              <strong>Policy :</strong>
                                            </td>
                                            <td ><p className="wt-activity2 mt-3 btn-link" onClick={() => getTicketPolicy(optionData)} style={{ cursor: "pointer", textDecoration: "none", color: "#0056b3" }}>
                                              View Cancellation Policy
                                            </p>
                                            </td>
                                          </tr>
                                        )}

                                      {optionData?.cancellationPolicy ?
                                        <tr>
                                          <td style={{ color: "#FF0000" }}>{optionData?.cancellationPolicy}</td>
                                        </tr> : ""}

                                    </tbody>
                                  </table>
                                </Row>
                                <Row className="justify-content-between pr-3" gutter={[2, 2]}>
                                  {tour.BookingStatus == "NOT-AVAILABLE" &&
                                    <Col className="wt-activity" md={24} xs={24}>
                                      <p style={{ color: "#FF0000" }}>On Request: Your booking is being processed, but confirmation is pending. Our team will contact you shortly</p>
                                    </Col>}
                                </Row>
                                {optionData.BookingStatus === 11 && optionData?.ticket?.map((x, i) => {
                                  return (x.barcode && (<>
                                    <Row className="justify-content-between my-3">
                                      <Col md={6} xs={24}>
                                        <img
                                          className="hotel-tag-1"
                                          src={optionData.tourImage}
                                          alt="First slide"
                                        />
                                      </Col>
                                      <Col md={4} xs={24}>
                                        <QRCode
                                          value={x.barcode}
                                          size={100}
                                          title={optionData.tourName}
                                          id={"packageQR" + index + "" + i}
                                        />
                                      </Col>
                                    </Row>
                                  </>))
                                })}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                  )
                  : null}
              </ul>
            </Col>
          </Row>
        </ul>
      </div>
    </div>
  );
};

export default TicketBuildPackage;
