import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Link
} from "@react-pdf/renderer";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

import hotelImg from "../../../assets/images/hotels/hotelsmall.png";
import NohotelImg from "../../../assets/images/hotels/no_img.png";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    padding: "0 10px",
  },
  innerSection: {
    position: "relative",
    paddingLeft: 30,
  },
  borderleft: {
    position: "absolute",
    width: 3,
    height: "100%",
    backgroundColor: "#e9ecef",
  },
  heading: {
    fontSize: 14,
    marginBottom: 10,
    color: "#274581",
  },
  title: {
    fontSize: 12,
    marginBottom: 4,
    color: "#274581",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 8,
  },
  stitle: {
    fontSize: 10,
  },
  textDanger: {
    color: "#dc3545",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    flexWrap: "wrap",
    alignItems: "center", // Vertically center items
    borderBottomWidth: 1, // Optional, to create a line under each row
    borderBottomColor: "#ddd", // Light gray color for border
  },

  wHalf: {
    width: "50%",
    marginBottom: 5,
  },
  card: {
    position: "relative",
    padding: 10,
    border: "1px solid #e9ecef",
    borderRadius: 2,
    width: 500
  },
  hotelimgWrapper: {
    width: 100,
  },
  hotelLogo: {
    width: 10,
  },
  tourImg: {
    width: 100,
  },
  mb10: {
    marginBottom: 10,
  },
  contentWrapper: {
    position: "relative",
    marginBottom: 10,
  },

  typeTitle: {
    backgroundColor: "#1e4167",
    width: 40,
    padding: "4px 5px",
    fontSize: 12,
    textAlign: "left",
    borderRadius: 4,
    color: "#fff",
    marginLeft: -50,
    marginBottom: 10,
  },

  timelineBadge: {
    position: "absolute",
    top: 9,
    left: -46,
    zIndex: 10,
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: 9,
    color: "#fff",
    backgroundColor: "#ffbc34",
  },
  description:
  {
    width: '100%',
    fontSize: 10,
    marginBottom: 8,
    fontSize: 12,
    marginTop: 2,
    color: '#0c5460',
    backgroundColor: '#d1ecf1',
    padding: 10,
    border: '1px solid rgb(172, 210, 217)',
    borderRadius: 2,
  },
  headerWrraper: {
    backgroundColor: "#e3f2fd",
    padding: "15px",
    marginBottom: 20,
  },
  hotelsPackageDetails: { width: 350, paddingLeft: 10 },
  addressDetails: {
    textAlign: "right",
    fontSize: 10,
    paddingBottom: 5,
  },
  flex5: {
    flex: 0.49,
  },
  logoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  textRight: {
    textAlign: "cen",
  },
  alignSelf: {
    alignSelf: "flex-end",
    marginBottom: 6,
  },
  justifyContentBetween: {
    justifyContent: "space-between",
  },
  verticalLine: {
    position: "absolute", // Position the line between cells
    left: "50%", // Center the line between the two columns
    top: -1,
    bottom: 0,
    width: 1, // The thickness of the vertical line
    backgroundColor: "#ddd", // Color of the vertical line
    height: "100%", // Full height of the row
    alignSelf: "stretch", // Ensures the line stretches to the container's height
  },
  container: {
    // borderWidth: 1, // Add border to the container to simulate table border
    borderColor: "#ddd", // Light color for the border
  },
  leftCell: {
    flex: 1,
    fontSize: 10,
    textAlign: "left", // Left-align the labels
    paddingLeft: 10,
    paddingVertical: 3,
  },

  rightCell: {
    flex: 1,
    fontSize: 10,
    textAlign: "right", // Right-align the values
    paddingRight: 10,
    // marginLeft: 300,
    paddingVertical: 3,
  },
});

// const Description = ({ tourShortDescription }) => {
//   let returnContentConst = <Text style={{ display: "inline" }}>{""}</Text>;
//   if (tourShortDescription) {
//     const parsedHtml = ReactHtmlParser(tourShortDescription);
//     parsedHtml.forEach((element) => {
//       if (element?.props && element?.props?.children) {
//         element.props.children.forEach((content) => {
//           returnContentConst = (
//             <Text style={{ display: "inline" }}>{content}</Text>
//           );
//         });
//       } else {
//         if (element)
//           returnContentConst = (
//             <Text style={{ display: "inline" }}>{element}</Text>
//           );
//       }
//     });
//     return returnContentConst;
//   } else {
//     return returnContentConst;
//   }
// };

const Description = ({ tourShortDescription }) => {
  let returnContentConst = <Text style={{ display: "inline" }}>{""}</Text>;

  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    const combinedContent = []; // Store all the content

    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          if (typeof content === "string") {
            combinedContent.push(content); // Add text content to the array
          } else {
            // If it's a nested element, push it as it is
            combinedContent.push(content);
          }
        });
      } else {
        if (typeof element === "string") {
          combinedContent.push(element); // Add text directly to the array
        } else {
          // If it's an element, push it
          combinedContent.push(element);
        }
      }
    });

    // Combine everything in the array into a single <Text> component
    returnContentConst = <Text style={{ display: "inline", flexWrap: "wrap" }}>{combinedContent}</Text>;
  }

  return returnContentConst;
};


const HotelImg = ({ img }) => {
  if (img) {
    return (
      <View style={styles.hotelimgWrapper}>
        <Image style={{ width: "100%" }} src={img} />
      </View>
    );
  } else {
    return (
      <View style={styles.hotelimgWrapper}>
        <Image style={{ width: "100%" }} src={NohotelImg} />
      </View>
    );
  }
};

const getHotelPaxCount = (roomsArray) => {
  if (roomsArray.length > 0) {
    let pax = roomsArray[0];
    return `Adult(s) ${pax?.AdultCount !== "0" ? pax?.AdultCount : 0} ${pax?.ChildCount !== "0" ? ", Child(s) " + pax?.ChildCount : " "
      } `;
  }
};

const getPaxCount = (pax) => {
  return `Adult(s) ${pax?.adult !== "0" ? pax?.adult : 0} ${pax?.child !== "0" ? ", Child(s) " + pax?.child : " "
    } ${pax?.infant !== "0" ? ", Infant(s) " + pax?.infant : " "}`;
};
const BASE = process.env.REACT_APP_BASE_URL;
const BuildPackageDoc = ({
  ticketData,
  agent,
  showPrice = true,
  withFare,
  Logo,
  activeCurrency,
  currencyValue = () => { },
}) => {
  const getStatus = (status) => {
    switch (status) {
      case 0: {
        return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;
      }
      case 1: {
        return <Text style={{ color: "#FF0000" }}>ALREADY CANCELLED</Text>;
      }
      case 2: {
        return <Text style={{ color: "#FFA500" }}>BLOCKED </Text>;
      }
      case 3: {
        return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;
      }
      case 7: {
        return <Text style={{ color: "#FF0000" }}>On Request</Text>;
      }
      case 11: {
        return <Text style={{ color: "#FF0000" }}>VOUCHERED</Text>;
      }
      default:
        return;
    }
  };

  const getTimebyUser = (optionData) => {
    if (
      optionData.optionName.includes("Airport") ||
      (optionData.optionName.includes("Transfer") &&
        optionData.transferName === "Private Transfers")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Document>
      {ticketData && Object.keys(ticketData).length > 0 ? (
        <>
          {ticketData.hotelsPackageDetails?.length ? (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <View style={styles.logoSection}>
                  <View style={{ ...styles.flex5 }}>
                    {Logo ? (
                      <Image
                        src={BASE + Logo.substring(1)}
                        style={{ height: 50, width: 100, marginBottom: 10 }}
                      ></Image>
                    ) : (
                      <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
                    )}
                  </View>
                  <View
                    style={{
                      ...styles.flex5,
                      ...styles.addressDetails,
                    }}
                  >
                    {ticketData?.agentDetails ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.AgencyName}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.Address1}{" "}
                          {ticketData?.agentDetails?.Address2}
                        </Text>
                        {ticketData?.agentDetails?.City ||
                          ticketData?.agentDetails?.State ? (
                          <Text style={{ ...styles.alignSelf }}>
                            {ticketData?.agentDetails?.City}{" "}
                            {ticketData?.agentDetails?.State}
                          </Text>
                        ) : null}

                        <Text style={{ ...styles.alignSelf }}>
                          Email: {ticketData?.agentDetails?.Email}
                        </Text>
                        {ticketData?.agentDetails?.Phone ? (
                          <Text style={{ ...styles.alignSelf }}>
                            Contact No.: {ticketData?.agentDetails?.Phone}
                          </Text>
                        ) : null}
                      </View>
                    ) : ticketData?.adminDetails?.length > 0 ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.Address}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CityName}{" "}
                          {ticketData?.adminDetails[0]?.StateName}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CountryName}{" "}
                          {ticketData?.adminDetails[0]?.PostalCode}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>

                <Text style={styles.heading}> INCLUSIONS DETAILS - HOTEL </Text>
                <View style={styles.innerSection}>
                  <View style={styles.borderleft}></View>

                  {ticketData.hotelsPackageDetails?.length > 0 ? (
                    <Text style={styles.typeTitle}>Hotel</Text>
                  ) : null}
                  {ticketData.hotelsPackageDetails.length > 0
                    ? ticketData.hotelsPackageDetails.map((hotel, index) => {
                      return (
                        <View
                          key={index + "hotel"}
                          style={styles.contentWrapper}
                        >
                          <View style={styles.timelineBadge}>
                            {hotel?.hotelImage ? (
                              <Image
                                src={hotel?.hotelImage}
                                source={{
                                  header: {
                                    "Access-Control-Allow-Origin": "*",
                                  },
                                }}
                              />
                            ) : (
                              <Image
                                style={styles.hotelLogo}
                                src={hotelImg}
                              />
                            )}
                          </View>

                          <View key={index} style={styles.card}>
                            <View
                              style={{
                                ...styles.row,
                              }}
                            >
                              <HotelImg img={hotel.hotelImage} />
                              <View style={styles.hotelsPackageDetails}>
                                <Text style={styles.title}>
                                  {hotel.HotelName}
                                </Text>
                                <View style={styles.row}>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    Check in Date :
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    {moment(hotel.CheckInDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    Check Out Date :
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    {moment(hotel.CheckOutDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    Room Type :
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    {hotel.Rooms[0].RoomName}
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    No. of Pax :
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    {getHotelPaxCount(hotel.Rooms)}
                                  </Text>

                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    No. of Nights :
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.stitle,
                                      ...styles.wHalf,
                                    }}
                                  >
                                    {hotel.Rooms[0].noOfNight}
                                  </Text>
                                  {withFare ? (
                                    <>
                                      {showPrice && (
                                        <Text
                                          style={{
                                            ...styles.stitle,
                                            ...styles.wHalf,
                                          }}
                                        >
                                          Amount :
                                        </Text>
                                      )}
                                      {showPrice && (
                                        <Text
                                          style={{
                                            ...styles.stitle,
                                            ...styles.wHalf,
                                          }}
                                        >
                                          INR {hotel.Rooms[0].pricePerRoom}
                                        </Text>
                                      )}
                                    </>
                                  ) : null}
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      );
                    })
                    : null}
                </View>
              </View>
            </Page>
          ) : null}

          {ticketData?.tourDetails?.length ? (
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <View style={styles.logoSection}>
                  <View style={{ ...styles.flex5 }}>
                    {Logo ? (
                      <Image
                        src={BASE + Logo.substring(1)}
                        style={{ height: 50, width: 100, marginBottom: 10 }}
                      ></Image>
                    ) : (
                      <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
                    )}
                  </View>
                  <View
                    style={{
                      ...styles.flex5,
                      ...styles.addressDetails,
                    }}
                  >
                    {ticketData?.agentDetails ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf, fontFamily: "Helvetica-Bold" }}>
                          {ticketData?.agentDetails?.AgencyName}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.Address1}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.Address2}
                        </Text>
                        {ticketData?.agentDetails?.City ||
                          ticketData?.agentDetails?.State ? (
                          <Text style={{ ...styles.alignSelf }}>
                            {ticketData?.agentDetails?.City}{" "}
                            {ticketData?.agentDetails?.State}
                          </Text>
                        ) : null}

                        <Text style={{ ...styles.alignSelf }}>
                          Email:{" "}
                          <Link href={`mailto:${ticketData?.agentDetails?.Email}`}>
                            {ticketData?.agentDetails?.Email}
                          </Link>
                        </Text>
                        {ticketData?.agentDetails?.Phone ? (
                          <Text style={{ ...styles.alignSelf }}>
                            Contact No. : {ticketData?.agentDetails?.Phone}
                          </Text>
                        ) : null}
                      </View>
                    ) : ticketData?.adminDetails?.length > 0 ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.Address}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CityName}{" "}
                          {ticketData?.adminDetails[0]?.StateName}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CountryName}{" "}
                          {ticketData?.adminDetails[0]?.PostalCode}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>

                {/* <View style={styles.headerWrraper}>
                  <Text style={styles.title}>Passenger Details</Text>
                  <View style={styles.row}>
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Guest Name: {ticketData?.leadPassenger.firstName}{" "}
                      {ticketData?.leadPassenger.lastName}
                    </Text>
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Email: {ticketData?.leadPassenger.email}
                    </Text>
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Contact: {ticketData?.leadPassenger.mobile}
                    </Text>
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                            Pan Card No: {ticketData?.leadPassenger.pan_no}
                        </Text>
                  </View>
                </View> */}
                <View style={[styles.headerWrraper, { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }]}>
                  {/* Passenger Details - Left Side */}
                  <View style={{ flex: 1, alignItems: 'flex-start' }}>
                    <Text style={styles.title}>Passenger Details</Text>
                    <View style={[styles.row, { flexDirection: "column", alignItems: 'flex-start', borderBottom: 0, marginBottom: 0 }]}>
                      <Text style={styles.subtitle}>
                        Guest Name: {ticketData?.leadPassenger.firstName}{" "}
                        {ticketData?.leadPassenger.lastName}
                      </Text>
                      <Text style={styles.subtitle}>
                        Email: {ticketData?.leadPassenger.email}
                      </Text>
                      <Text style={styles.subtitle}>
                        Contact: {ticketData?.leadPassenger.mobile}
                      </Text>
                    </View>
                  </View>
                  {/* Payment Details - Right Side */}
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <Text style={styles.title}>Payment Details</Text>
                    <View style={[styles.row, { flexDirection: "column", alignItems: "flex-end", borderBottom: 0, marginBottom: 0 }]}>
                      <Text style={styles.subtitle}>
                        Confirmation No: {ticketData.bookingRefNo}
                      </Text>
                      {!agent && (
                        <Text style={styles.subtitle}>
                          Provider Ref No: {ticketData?.ProviderRefNo}
                        </Text>
                      )}

                      <Text style={styles.subtitle}>
                        Payment Mode:{" "}
                        {ticketData?.partPayment?.isPartPayment === true ? "Part" : "Full"} Payment
                      </Text>
                      {withFare && (
                        <>
                          <Text style={styles.subtitle}>
                            Total Fare: {ticketData.partPayment.serviceTotal}
                          </Text>
                          {ticketData.partPayment.isPartPayment === true ? (
                            <>
                              <Text style={styles.subtitle}>
                                Paid Amount: {ticketData?.partPayment?.partPaymentAmount}
                              </Text>
                              <Text style={[styles.subtitle, styles.textDanger]}>
                                Due Amount:
                                {ticketData?.partPayment?.dueAmount}
                              </Text>
                              {/* <Text style={styles.subtitle}>
                            Last Date: {ticketData?.partPayment?.PaymentDueDate}
                          </Text>  */}
                            </>
                          ) : null}
                        </>)}
                      {ticketData?.partPayment?.isPartPayment === true && (
                        <Text style={[styles.subtitle, styles.textDanger]}>
                          Last Date:
                          {ticketData?.partPayment?.PaymentDueDate}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>

                {/* <View style={{ ...styles.headerWrraper, ...styles.mb_5 }}>
                  <Text style={styles.title}>Payment Details</Text>
                  <View style={styles.row}>
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Reference Number: {ticketData?.bookingRefNo}
                    </Text>
                    {!agent && (
                      <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                        Provider Ref No: {ticketData?.ProviderRefNo}
                      </Text>
                    )}
                    <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                      Payment Mode:{" "}
                      {ticketData?.partPayment?.isPartPayment === true
                        ? " Part"
                        : " Full"}{" "}
                      Payment
                    </Text>
                    {withFare && (
                      <>
                        <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                          Total Fare: {ticketData?.partPayment?.serviceTotal}
                        </Text>
                        {ticketData?.partPayment?.isPartPayment === true && (
                          <>
                            <Text
                              style={{ ...styles.subtitle, ...styles.wHalf }}
                            >
                              Paid Amount:{" "}
                              {ticketData?.partPayment?.partPaymentAmount}
                            </Text>
                            <Text
                              style={{
                                ...styles.subtitle,
                                ...styles.wHalf,
                                ...styles.textDanger,
                              }}
                            >
                              Due Amount: {ticketData?.partPayment?.dueAmount}
                            </Text>
                          </>
                        )}
                      </>
                    )}
                    {ticketData?.partPayment?.isPartPayment === true && (
                      <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                        Last Date: {ticketData?.partPayment?.PaymentDueDate}
                      </Text>
                    )}
                  </View>
                </View> */}

                <View></View>
                <Text style={styles.heading}> DAY WISE ITINERARY - TOUR</Text>
                <View style={styles.innerSection}>
                  <View style={styles.borderleft}></View>
                  {ticketData?.tourDetails?.length > 0 ? (
                    <Text style={styles.typeTitle}>Tours</Text>
                  ) : null}

                  {ticketData?.tourDetails?.length > 0
                    ? ticketData?.tourDetails.map(
                      (tourList, index) =>
                        tourList &&
                        tourList?.length > 0 &&
                        tourList.map((tour, tourIndex) => {
                          return (
                            <React.Fragment>
                              {index !== 0 ? <Text break /> : null}
                              <View key={index} style={styles.contentWrapper}>
                                <View style={styles.timelineBadge}>
                                  <Text>Day {index + 1}</Text>
                                </View>

                                <View key={index} style={styles.card}>
                                  <Text style={styles.title}>
                                    {tour.tourName}
                                  </Text>

                                  {/* <Text style={styles.subtitle}>
                                    Tour Date :{" "}
                                    {moment(tour.tourDate, "YYYY-MM-DD").format(
                                      "DD-MM-YYYY"
                                    )}
                                  </Text> */}
                                  {/* <Text
                                    style={{
                                      marginLeft: "50%",
                                      marginTop: -16,
                                      fontSize: 10,
                                      marginBottom: 5,
                                    }}
                                  >
                                    {moment(tour.tourDate, "YYYY-MM-DD").format(
                                      "DD MMM YYYY"
                                    )}
                                  </Text> */}

                                  <Text style={styles.subtitle}>
                                    {tour.optionName}
                                  </Text>

                                  {/* <Text style={styles.subtitle}>
                                    Booking Status :{" "}
                                    {getStatus(tour?.BookingStatus)}
                                  </Text>

                                  <Text style={styles.subtitle}>
                                    Booking Reference No : {tour?.BookingRefNo}
                                  </Text> */}

                                  {!agent ? (
                                    <Text style={styles.subtitle}>
                                      Provider Reference No :{" "}
                                      {tour?.ProviderRefNo}
                                    </Text>
                                  ) : null}

                                  <View style={styles.row}>
                                    <Image
                                      style={[styles.tourImg, { marginBottom: '4px' }]}
                                      src={tour.tourImage}
                                    />
                                  </View>
                                  {!(
                                    tour.tourId?.startsWith("EXT") ||
                                    tour.tourId?.startsWith("Ext")
                                  ) &&
                                    tour.transferName !==
                                    "Private Transfers" && (
                                      <View style={styles.description}>
                                        <Description
                                          tourShortDescription={
                                            tour.tourShortDescription
                                          }
                                        />
                                      </View>
                                    )}

                                  <View style={styles.container}>

                                    {/* <Text style={{...styles.stitle, ...styles.wHalf,}}> Booking Status :</Text>
                                  <Text style={{...styles.stitle, ...styles.wHalf,}}> {getStatus(tour?.BookingStatus)}</Text> */}

                                    <View style={styles.row}>
                                      <Text style={styles.leftCell}>Booking Status :</Text>
                                      <View style={styles.verticalLine} />
                                      <Text style={styles.rightCell}>{getStatus(tour?.BookingStatus)}</Text>
                                    </View>

                                    <View style={styles.row}>
                                      <Text style={styles.leftCell}> Booking ID:</Text>
                                      <View style={styles.verticalLine} />
                                      <Text style={styles.rightCell}> {tour?.BookingRefNo}</Text>
                                    </View>

                                    <View style={styles.row}>
                                      <Text style={styles.leftCell}> Travel Date :</Text>
                                      <View style={styles.verticalLine} />
                                      <Text style={styles.rightCell}>{moment(
                                        tour.tourDate,
                                        "YYYY-MM-DD"
                                      ).format("DD-MM-YYYY")}</Text>
                                    </View>

                                    {(tour.tourId?.startsWith("EXT") ||
                                      tour.tourId?.startsWith("Ext")) &&
                                      (tour.BookingStatus === 3) ? (
                                      <View style={styles.row}>
                                        <Text style={styles.leftCell}>
                                          Last Vouchered Date :
                                        </Text>
                                        <View style={styles.verticalLine} />
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          {moment(tour.tourDate, "YYYY-MM-DD")
                                            .subtract(4, "days")
                                            .format("ddd DD MMM YYYY")}
                                        </Text>
                                      </View>
                                    ) : null}

                                    {/* {(tour.tourId?.startsWith("EXT") ||
                                      tour.tourId?.startsWith("Ext")) &&
                                      (tour.BookingStatus === 11 ||
                                        (tour.BookingStatus === 7 &&
                                          tour.transferName ===
                                          "Private Transfers")) ? (
                                      <View style={styles.row}>
                                        <Text style={styles.leftCell}>
                                          Last Cancellation Date :
                                        </Text>
                                        <View style={styles.verticalLine} />
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          {moment(tour.tourDate, "YYYY-MM-DD")
                                            .subtract(4, "days")
                                            .format("ddd DD MMM YYYY")}
                                        </Text>
                                      </View>
                                    ) : null} */}

                                    <View style={styles.row}>
                                      <Text style={styles.leftCell}> Transfer Option :</Text>
                                      <View style={styles.verticalLine} />
                                      <Text style={styles.rightCell}>{tour.transferName}</Text>
                                    </View>

                                    {!getTimebyUser(tour) && tour.duration && (
                                      <View style={styles.row}>
                                        <Text
                                          style={styles.leftCell}
                                        >
                                          Duration :
                                        </Text>
                                        <View style={styles.verticalLine} />
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          {tour.duration}
                                        </Text>
                                      </View>
                                    )}

                                    {tour?.ticket?.length > 0 &&
                                      tour?.ticket.some(
                                        (ticket) => ticket?.timeSlot
                                      ) ? (
                                      <View style={styles.row}>
                                        <Text
                                          style={styles.leftCell}
                                        >
                                          Slot:
                                        </Text>
                                        <View style={styles.verticalLine} />
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          {tour?.ticket
                                            .filter(ticket => ticket?.timeSlot) // Filter out undefined or empty timeSlot values
                                            .map(ticket => ticket.timeSlot)
                                            .join(', ')}
                                        </Text>
                                      </View>
                                    ) : (
                                      <View style={styles.row}>
                                        <Text
                                          style={styles.leftCell}
                                        >
                                          {tour.transferName === "Private Transfers"
                                            ? "Pickup time"
                                            : "Start Time"}
                                        </Text>
                                        <View style={styles.verticalLine} />
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          {tour ? tour?.startTime : 'Time Not Available'}
                                        </Text>
                                      </View>
                                    )}

                                    {tour.pickup !== "" && (
                                      <View style={styles.row}>
                                        <Text
                                          style={styles.leftCell}
                                        >
                                          {tour.tourName.includes(
                                            "Pick Up"
                                          )
                                            ? "Drop Off Location"
                                            : "Pick Up Location"}
                                        </Text>
                                        <View style={styles.verticalLine} />
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          {tour.pickup}
                                        </Text>
                                      </View>
                                    )}

                                    <View style={styles.row}>
                                      <Text
                                        style={styles.leftCell}
                                      >
                                        Number of Passengers :
                                      </Text>
                                      <View style={styles.verticalLine} />
                                      <Text
                                        style={styles.rightCell}
                                      >
                                        {getPaxCount(tour)}
                                      </Text>
                                    </View>

                                    {withFare ? (
                                      <View style={styles.row}>
                                        {showPrice && (
                                          <Text
                                            style={styles.leftCell}
                                          >
                                            Amount :
                                          </Text>
                                        )}
                                        {showPrice && (
                                          <View style={styles.verticalLine} />
                                        )}
                                        <Text
                                          style={styles.rightCell}
                                        >
                                          INR {tour.serviceTotal}
                                        </Text>
                                      </View>
                                    ) : null}

                                  </View>
                                </View>
                              </View>
                            </React.Fragment>
                          );
                        })
                    )
                    : null}
                </View>
              </View>
            </Page>
          ) : null}
        </>
      ) : null}
    </Document>
  );
};

export default BuildPackageDoc;
