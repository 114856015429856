import React, { useState, useEffect } from "react";

import * as ReactBootstrap from "react-bootstrap";
import { Card, Col, Row, Grid, Skeleton, Collapse, Spin } from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import Hotels from "../../../components/Hotels/Hotels";
import HotelContainer from "./HotelContainer";
import HotelSort from "../HotelSort/HotelSort";
import Filter from "../../../common/Filters/Filter";
import ApiClient from "../../../helpers/ApiClient";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import SkeletonLayout from "../../../components/Skeleton/Skeleton";
import FilterSkeletonLayout from "../../../components/FilterSkeleton/FilterSkeleton";
import queryString from "query-string";
import NoResultFound from "../../../components/ErrorPages/NoResultFound";
import moment from "moment";
import "../HotelResults/HotelResults.scss";

// import {
//   BannerAdDisplay,
//   getBannerAdsSrc,
//   checkIfDisplayAdd,
// } from "../../../components/BannerAds/BannerAdd";
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const dateFormat = "DD-MM-YYYY";
const oriDateFormat = "YYYY-MM-DD";

const HotelResults = ({ hotelParam = false, isFromPackage = false }) => {
  const { md } = useBreakpoint();
  const { user } = useAuthContext();
  const [mainHotelsListResp, setMainHotelsListResp] = useState([]);
  const [listOfHotels, setListOfHotels] = useState([]);
  const [key, setKey] = useState([]);
  const [traceId, setTraceId] = useState(null);
  const [isHotelSearchLoad, setIsHotelSearchLoad] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [UniqueCities, setUniqueCities] = useState([]);
  const [showLoader, setShowLoader] = useState(isLoading);

  useEffect(() => {
    fetchStaticData();
  }, [hotelParam]);


  useEffect(() => {
    const handleScroll = () => {
      const blurSection = document.querySelector(".blur-hotel-list");
      if (blurSection) {
        const rect = blurSection.getBoundingClientRect();
         
        if (rect.bottom > 0 && rect.top < window.innerHeight) {
          setShowLoader(true); // Show when .blur-hotel-list is visible
        } else {
          setShowLoader(false); // Hide when it's fully out of view
        }
      }
    };

    if (isLoading) {
      setShowLoader(true);
      window.addEventListener("scroll", handleScroll);
    } else {
      setShowLoader(false);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading]);


  const fetchStaticData = () => {
    setMainHotelsListResp([]);
    setListOfHotels([]);
    setIsLoading(true);
    setIsHotelSearchLoad(true);

    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);
      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
        let staticObj = {
          traceId: "string",
          cityId: hotelCityCode.cityId,
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        getStaticData(staticObj);
      }
    } else if (Object.keys(hotelParam).length > 0) {
      let staticObj = {
        traceId: "string",
        cityId: hotelParam.hotelCityCode,
      };
      getStaticData(staticObj);
    }
  };

  const getStaticData = (staticObj) => {
    ApiClient.post("hotels-v2/hotelstaticdetails", staticObj)
      .then((result) => {
        return result;
      })
      .then((res) => {
        if (res?.status === 200) {
          if (!res.data.errors) {
            setTraceId(res.data.traceId);
            const hotelDetails = res.data.hotelDetails.map((hotel) => {
              return {
                ...hotel,
                hotelCode: hotel.hotelId,
                hotelName: hotel.propertyName,
                isVisible: true,
                isPriceAvailable: false,
              };
            });

            setMainHotelsListResp(hotelDetails);
            setListOfHotels(hotelDetails);
            fetchHotelSearch(hotelDetails);
          } else {
            setMainHotelsListResp([]);
            setListOfHotels([]);
          }
        } else {
          setMainHotelsListResp([]);
          setListOfHotels([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const fetchHotelSearch = (hotelDetails) => {
    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);
      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
        let searchReqObj = {
          checkInDate: hotelSearchParams.checkInDate,
          checkOutDate: hotelSearchParams.checkOutDate,
          // checkOutDate: "2021-12-31T08:33:14.744",
          hotelCityCode: hotelCityCode.cityId,
          roomGuests: JSON.parse(hotelSearchParams.roomGuests),
          nationality: hotelSearchParams.nationality,
          countryCode: hotelSearchParams.nationality,
          isHotelDescriptionRequried: true,
          currency: hotelSearchParams.currency,
          traceId: "string",
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        getHotelDetails(searchReqObj, hotelDetails);
      }
    } else if (Object.keys(hotelParam).length > 0) {
      let searchReqObj = {
        ...hotelParam,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
      };
      getHotelDetails(searchReqObj, hotelDetails);
    }
  };

  const getHotelDetails = (searchReqObj, hotelDetails) => {
    setUniqueCities([])
    ApiClient.post("hotels-v2/hotelsearch", searchReqObj)
      .then((result) => {
        return result;
      })
      .then((res) => {
        if (res.status === 200) {
          if (!res.data.errors) {
            setTraceId(res.data.traceId);
            const hotelsdata = res.data.hotels;
            // get the search hotel data by matching hotelCode
            let combinedHotelDetails = [];
            
            // get city from hotel data by karthik 27-December-2023
            let getCity = res.data.hotels
            let uniqueCity = new Set();
            getCity.forEach(hotel => {
              if (hotel.addresses[0] && hotel.addresses[0].cityName) {
                  let cityName = cityNameFormatting(hotel.addresses[0].cityName);
                  uniqueCity.add(cityName);
              }
          });
          setUniqueCities(Array.from(uniqueCity))

            // if (hotelsdata.length > 0) {
            //   for (let i = 0; i < hotelDetails.length; i++) {
            //     let matchHotelSearchData = hotelsdata.filter(
            //       (data) => data.hotelCode == hotelDetails[i].hotelId
            //     );
            //     if (matchHotelSearchData.length === 1) {
            //       combinedHotelDetails.push({
            //         ...hotelDetails[i],
            //         ...matchHotelSearchData[0],
            //         isPriceAvailable: true,
            //       });
            //     } else {
            //       combinedHotelDetails.push({
            //         ...hotelDetails[i],
            //       });
            //     }
            //   }
            // }

            if (hotelsdata.length > 0) {
              for (let i = 0; i < hotelsdata.length; i++) {
                let matchHotelSearchData = hotelDetails.filter(
                  (data) => data.hotelId == hotelsdata[i].hotelCode
                );
                combinedHotelDetails.push({
                  ...matchHotelSearchData[0],
                  ...hotelsdata[i],
                  isPriceAvailable: true,
                });
              }
            }

            setMainHotelsListResp(combinedHotelDetails);
            setIsHotelSearchLoad(false);
          } else {
            setMainHotelsListResp([]);
            setListOfHotels([]);
          }
        } else {
          setMainHotelsListResp([]);
          setListOfHotels([]);
        }
        setIsHotelSearchLoad(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsHotelSearchLoad(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  // name fomating by nitin sir (Karthik 27-December-2023)
  const cityNameFormatting = (val) => {
    let string = val.split(' ');
    let joinString = '';
    string.forEach((city, i) => {
      if (i < string.length - 1) { // Check if it's not the last word
        joinString += city.charAt(0).toUpperCase() + city.slice(1) + ' ';
      } else {
        joinString += city.charAt(0).toUpperCase() + city.slice(1);
      }
    });
    return joinString;
  }

  /* Fetching Hotel dump API */
  // const fetchHotelSearch = () => {
  //   setIsLoading(true);
  //   setListOfHotels([]);
  //   setMainHotelsListResp([]);

  //   if (!hotelParam) {
  //     const hotelSearchParams = queryString.parse(window.location.search);
  //     let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
  //     let searchReqObj = {
  //       checkInDate: moment(hotelSearchParams.checkInDate),
  //       checkOutDate: moment(hotelSearchParams.checkOutDate),
  //       hotelCityCode: hotelCityCode.cityId,
  //       roomGuests: JSON.parse(hotelSearchParams.roomGuests),
  //       nationality: hotelSearchParams.nationality,
  //       countryCode: hotelSearchParams.nationality,
  //       isHotelDescriptionRequried: true,
  //       currency: hotelSearchParams.currency,
  //       traceId: "string",
  //     };

  //     getHotels(searchReqObj);
  //   } else if (Object.keys(hotelParam).length > 0) {
  //     getHotels(hotelParam);
  //   }
  // };

  // const getHotels = (searchReqObj) => {
  //   if (searchReqObj == {}) {
  //     setIsLoading(false);
  //     return;
  //   }

  //   let req = {
  //     ...searchReqObj,
  //     userId: user?.UserID ?? 1,
  //     roleType: user?.Role?.RoleId ?? 4,
  //     membership: user?.Membership ?? 1,
  //   };
  //   ApiClient.post("hotels-v2/hotelsearch", req)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         if (!res.data.errors) {
  //           setTraceId(res.data.traceId);
  //           const hotelsdata = res.data.hotels.map((hotel) => {
  //             return {
  //               ...hotel,
  //               isVisible: true,
  //             };
  //           });

  //           setMainHotelsListResp(hotelsdata);
  //         } else {
  //           setMainHotelsListResp([]);
  //         }
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  return (
    <div className="hotels_results_page ">
      {
        isLoading ? ( showLoader && (
          <Spin tip="Loading" size="large" className="hotel-spin-loader" />
        )
          ) : (
            <></>
        )
      }
      <div className={`hotels-page-wrapper ${isLoading?"blur-hotel-list":""}`}>
        {isFromPackage ? null : (
          <section className="hotels_modify">
            <div className="hotel-modify-container">
              <Collapse
                activeKey={md ? ["1"] : key}
                showArrow={false}
                onChange={(val) => {
                  setKey(val);
                }}
              >
                <Panel
                  showArrow={false}
                  header={
                    <span className="hotels-hide-search">Modify Search</span>
                  }
                  key="1"
                >
                  <Hotels
                    hotelSearchAPI={fetchStaticData}
                    modifySearch={true}
                  />
                </Panel>
              </Collapse>
            </div>
          </section>
        )}
        {isLoading ? (
          <section>
            <div className="hotel-skeleton-layout">
              <div className="hotel-skeleton-layout-container">
                <Row gutter={16}>
                  <Col md={6} xs={0} className="filter-skeleton">
                    <FilterSkeletonLayout />
                  </Col>
                  <Col md={18} xs={24} className="result-body-skeleton">
                    <Card className="card-skeleton">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Card>

                    {[...Array(6)].map((i) => (
                      <SkeletonLayout key={i} />
                    ))}
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        ) : mainHotelsListResp.length > 0 ? (
          <section className="hotels_list">
            <div className="hotels-list-wrapper">
              <div className="list-container">
                <div className="filters-box">
                  <Filter
                    count={mainHotelsListResp.length}
                    data={mainHotelsListResp}
                    setListOfHotels={setListOfHotels}
                    isHotelSearchLoad={isHotelSearchLoad}
                    UniqueCities={UniqueCities}
                  />
                </div>
                <div className="hotels-box">
                  {isHotelSearchLoad ? (
                    <Card className="card-skeleton">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Card>
                  ) : (
                    <HotelSort
                      listOfHotels={mainHotelsListResp}
                      setListOfHotels={setListOfHotels}
                      showNetFare={showNetFare}
                      setShowNetFare={setShowNetFare}
                    />
                  )}
                  {listOfHotels.length > 0 ? (
                    <HotelContainer
                      isFromPackage={isFromPackage}
                      listOfHotels={listOfHotels}
                      traceId={traceId}
                      isHotelSearchLoad={isHotelSearchLoad}
                      showNetFare={showNetFare}
                    />
                  ) : (
                    <CustomNoResultFound title={"No Hotels Available"} />
                  )}
                </div>
              </div>
            </div>
          </section>
        ) : (
          <NoResultFound />
        )}
      </div>

      <ReactBootstrap.Row className="fiters-value-hotel">
        <ReactBootstrap.Col md={6} xs={6}>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-demo"
              data-toggle="modal"
              data-target="#myModal"
            >
              <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
            </button>
          </div>
        </ReactBootstrap.Col>

        <ReactBootstrap.Col md={6} xs={6}>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-demo"
              data-toggle="modal"
              data-target="#myModal-sort"
            >
              <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
              &nbsp;Sort by
            </button>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>

      <div
        className="modal left fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header hotel-modal-filters">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                Hotel Filters
              </h4>
            </div>

            <div className="modal-body">
              {/* <Filter
                count={mainHotelsListResp.length}
                data={mainHotelsListResp}
                setListOfHotels={setListOfHotels}
              /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="myModal-sort">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Most popular</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <ul className="sortby-ul">
                <li>
                  <a href="#">Distance from the destination</a>
                </li>
                <li>
                  <a href="#">Top Rated</a>
                </li>
                <li>
                  <a href="#">Category</a>
                </li>
                <li>
                  <a href="#">Price</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelResults;
