import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import SeoMetaData from "../../common/Seo/SeoMetaData";
const OurTeam = () => {
  return (
    <>
      <CmsPages cmsId={1} pageTitle="Our Team" />;
      <SeoMetaData path={window.location.pathname} />
    </>
  )
};

export default OurTeam;
