import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Radio,
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Button,
  Input,
  message,
} from "antd";
import { Helmet } from "react-helmet";
import "../flight-search/FlightSearch.scss";
import arrowImg from "../../assets/images/flight-arrow.png";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
// import MultiCityList from "./MultiCityList";
import {
  createQueryParamsFromRequest,
  createSearchFromForm,
  getSearchRequestFromQuery,
  MapRequestToForm,
} from "./SearchRequestHelper";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";

import MultiCityList from "./MultiCityList";
import { OriDesValidate } from "../../helpers/CustomValidators";
import { AppConstants } from "../../helpers/constants";
const FlightSearch = (props) => {
  const dateFormat = "YYYY-MM-DD";
  let history = useHistory();
  const origin = useRef();
  const destination = useRef();

  const dateBox = useRef();
  const returnDateBox = useRef();
  const paxBox = useRef();
  const searchBtn = useRef();
  const [showDate, setShowDate] = useState(false);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();

  const departureDate = moment();
  const [arrivalDate, setArrivalDate] = useState(moment());

  const [travel_type, setTravelType] = useState("oneWay");
  const [passengers, setPassengers] = useState({
    adults: 1,
    childs: 0,
    infants: 0,
  });

  const [toggle, setTogle] = useState(false);

  const setUpFormInitial = () => {
    let results = false;
    if (props.modify) {
      let query_request = getSearchRequestFromQuery();
      results = MapRequestToForm(query_request);
    } else {
      let stored_request = JSON.parse(
        sessionStorage.getItem("flight_search_request")
      );
      results = MapRequestToForm(stored_request);
    }

    if (results) {
      setTravelType(results.airTravelType);
      // setCabinClass(results.cabinClass);
      form.setFieldsValue(results);
      setPassengers({
        adults: results.adults,
        childs: results.childs,
        infants: results.infants,
      });
    }
  };

  useEffect(() => {
    setUpFormInitial();
  }, []);

  const updateTravelType = (val) => {
    if (val) {
      if (val === "oneWay") {
        form.setFieldsValue({
          arrival: "",
        });
      }
      setTravelType(val);
    }
  };

  const onFormSubmit = (formData) => {
    if (formData.origin === formData.destination) {
      message.error("Origin and Destination cannot be same", 3);
      return false;
    }

    let includeCarrier = "";
    let stopOver = "none";
    const searchRequest = createSearchFromForm(
      formData,
      travel_type,
      // cabinClass,
      passengers,
      includeCarrier,
      stopOver
    );

    const params = createQueryParamsFromRequest(searchRequest);
    if (props.modify) {
      history.replace("/flight/results?" + params);
      props.onFormSubmit();
    } else {
      history.push("/flight/results?" + params);
    }
  };

  const intitial = {
    cabinClass: "Economy",
    departure: departureDate,
    originDestinationFields: [{}, {}],
  };
  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };
  const handleFocus = () => {
    searchBtn.current.focus();
  };
  const updatePaxCount = (opr) => {
    if (passengers.adults + passengers.childs + passengers.infants == 9) {
      if (opr == "adultInc" || opr == "infantInc" || opr == "childInc") {
        return;
      }
    }
    switch (opr) {
      case "adultInc":
        setPassengers({ ...passengers, adults: passengers.adults + 1 });
        break;
      case "adultDec":
        if (passengers.adults > 1) {
          setPassengers({ ...passengers, adults: passengers.adults - 1 });
        }
        break;
      case "childInc":
        setPassengers({ ...passengers, childs: passengers.childs + 1 });
        break;
      case "childDec":
        if (passengers.childs >= 1) {
          setPassengers({ ...passengers, childs: passengers.childs - 1 });
        }
        break;
      case "infantInc":
        setPassengers({ ...passengers, infants: passengers.infants + 1 });
        break;
      case "infantDec":
        if (passengers.infants >= 1) {
          setPassengers({ ...passengers, infants: passengers.infants - 1 });
        }
        break;
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(arrivalDate).startOf("day");
  };

  const PaxInput = () => (
    <Form.Item className="flight-passenger">
      <Input
        ref={paxBox}
        size="large"
        readOnly
        value={passengers.adults + passengers.childs + passengers.infants}
        style={{ cursor: "pointer" }}
        onClick={() => setTogle(toggle ? false : true)}
      />
      <div
        style={{
          display: toggle ? "block" : "none",
        }}
        className="pax-modal"
      >
        <div className="pax-modal-wrapper">
          <div className="pax-modal-arrow"></div>
          <li>
            <div className="pax-label">
              <p>Adults</p>
              <span>over 12 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("adultDec")}
              ></i>
              <span>{passengers.adults}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("adultInc")}
              ></i>
            </div>
          </li>

          <li>
            <div className="pax-label">
              <p>Children</p>
              <span>2 - 12 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("childDec")}
              ></i>
              <span>{passengers.childs}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("childInc")}
              ></i>
            </div>
          </li>
          <li>
            <div className="pax-label">
              <p>Infants</p>
              <span>upto 2 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("infantDec")}
              ></i>
              <span>{passengers.infants}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("infantInc")}
              ></i>
            </div>
          </li>
          {passengers.infants > passengers.adults ? (
            <li>
              <span
                style={{
                  fontSize: "11px",
                  color: "red",
                }}
              >
                The number of infants cannot be grater than the number of
                adults.
              </span>
            </li>
          ) : (
            ""
          )}
          {passengers.adults + passengers.child > 9 ? (
            <li>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                style={{ fontSize: "30px" }}
              ></i>
              &nbsp;&nbsp;
              <span
                style={{
                  fontSize: "11px",
                  color: "blue",
                }}
              >
                Booking for more than 9 persons will be procced as group
                booking.
              </span>
            </li>
          ) : (
            ""
          )}
          <div>
            <Button
              block
              className="pax-ready-btn"
              onClick={() => {
                setTogle((prev) => !prev);
                if (toggle) {
                  handleFocus();
                }
              }}
            >
              Ready
            </Button>
          </div>
        </div>
      </div>
    </Form.Item>
  );

  const [dataFlip, setDataFlip] = useState(false);

  const handleSwap = () => {
    const origin = form.getFieldValue("origin");
    const destination = form.getFieldValue("destination");

    if (origin && destination) {
      form.setFieldsValue({
        origin: destination,
        destination: origin,
      });
      setDataFlip((prev) => !prev);
    }
  };

  const onChangeOriginDate = async (momentdate, _) => {
    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    
    setArrivalDate(momentdate);
    
    if (travel_type === "roundTrip")
      {
    form.setFieldsValue({
      arrival: momentdate,
    });
  }
    const toDate = form.getFieldValue("arrival");
    if (travel_type === "roundTrip" && toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = await moment(momentdate).clone().add(2, "days");

        //let newTodate = moment(momentdate).add(2, "days");
        form.setFieldsValue({
          arrival: newTodate,
        });
      }
    }
  };

  return (
    <div className="hotels_search_box">
      <Helmet>
        <title>
          {/* Book Flights, Hotels, Bus Tickets & Holidays -{" "} */}
          Book Cheapest Flights & Hotels with Best Indian Flight & Travel Booking Website - Shop Your Trip -{" "}
          {AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          // content=" Get cheap flight tickets, hotels, bus tickets and holiday packages with a best tour & travel company of India- Shopyourtrip.com.  Avail complete travel solutions for a lifetime journey. "
             content=" Get the best travel experience with Shop Your Trip, the best online travel agency in India for flights and travel booking packages. Find the cheapest flights, and best hotels and customize your holiday packages. Enjoy exclusive hotel stays, & stress-free bookings for your next vacation with Shop Your Trip. "
        />
      </Helmet>
      <div className="hotels-wrapper">
        <h2 className="lowest-ne-p">Search Lowest Airfare</h2>
        <div className="class-container">
          <div>
            <Radio.Group
              onChange={(e) => updateTravelType(e.target.value)}
              value={travel_type}
            >
              <Radio
                className={`bdr-icon-f ${
                  travel_type === "oneWay" ? "tripTypeActive" : ""
                }`}
                value={"oneWay"}
              >
                One way
              </Radio>
              <Radio
                className={`bdr-icon-f ${
                  travel_type === "roundTrip" ? "tripTypeActive" : ""
                }`}
                value={"roundTrip"}
              >
                Round trip
              </Radio>
              {/* <Radio value={"multidestination"}>Multi-city</Radio> */}
            </Radio.Group>
          </div>
          {/* <div className="select_wrapper">
            <span className="class-type">Class :</span>
            <Select
              value={cabinClass}
              className="classTypeSelect"
              onChange={updateCablinClass}
              style={{ width: "150px" }}
            >
              <Option value="Economy">Economy</Option>
              <Option value="premiumEconomy">Economy Premium</Option>
              <Option value="Business">Business</Option>
              <Option value="first">First Class</Option>
              <Option value="premiumFirst  ">Premium First Class</Option>
            </Select>
          </div> */}
        </div>

        <div id="oneWayForm">
          {props.modify && (
            <Row className="modifySelect" justify="space-between">
              <Col md={12} xs={12}>
                <Select
                  value={travel_type}
                  onChange={updateTravelType}
                  className="modify-selectors"
                >
                  <Option value={"oneWay"}>One Way</Option>
                  <Option value={"roundTrip"}>RoundTrip</Option>
                  {/* <Option value={"multidestination"}>Multi-city</Option> */}
                </Select>
              </Col>
              {/* <Col md={12} xs={12} style={{ textAlign: "right" }}>
                <Select
                  value={cabinClass}
                  onChange={updateCablinClass}
                  className="modify-selectors"
                  style={{ width: "150px" }}
                >
                  <Option value="Economy">Economy</Option>
                  <Option value="premiumEconomy">Economy Premium</Option>
                  <Option value="Business">Business</Option>
                  <Option value="first">First Class</Option>
                  <Option value="premiumFirst  ">Premium First Class</Option>
                </Select>
              </Col> */}
            </Row>
          )}

          <Form
            form={form}
            onFinish={onFormSubmit}
            className="flight-search flight-bg-side"
            layout="vertical"
            initialValues={intitial}
          >
            <Row className="flight-alert-sec">
              {travel_type != "multidestination" ? (
                <>
                  <Col md={5} xs={24} className="originwrapper">
                    <AirportAutoComplete
                      formItemProps={{
                        name: "origin",

                        rules: [
                          {
                            required: true,
                            message: "Please Specify The City",
                          },
                          {
                            validator: (_, value) => {
                              let destination =
                                form.getFieldValue("destination");
                              return OriDesValidate(value, destination);
                            },
                          },
                        ],
                      }}
                      selectProps={{
                        size: "large",
                        placeholder: "Origin",
                      }}
                      refName={origin}
                      focusRef={destination}
                      handleOnSubmit={handleOnSubmit}
                      dataFlip={dataFlip}
                    />
                    <img
                      className="arrow-bus-inr"
                      src={arrowImg}
                      alt="Arrow img"
                      onClick={handleSwap}
                    />
                  </Col>

                  <Col md={5} xs={24} className="destination">
                    <AirportAutoComplete
                      formItemProps={{
                        name: "destination",
                        rules: [
                          {
                            required: true,
                            message: "Please Specify The City",
                          },
                          {
                            validator: (_, value) => {
                              let origin = form.getFieldValue("origin");
                              return OriDesValidate(origin, value);
                            },
                          },
                        ],
                      }}
                      selectProps={{
                        size: "large",
                        placeholder: "Destination",
                      }}
                      refName={destination}
                      focusRef={dateBox}
                      handleOnSubmit={handleOnSubmit}
                      dataFlip={dataFlip}
                    />
                  </Col>
                  <Col md={3} xs={24}>
                    <Form.Item
                      name="departure"
                      rules={[
                        {
                          required: true,
                          message: "Please Select a Date",
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={false}
                        style={{ width: "100%" }}
                        className="train-search-btn"
                        size="large"
                        format={"DD-MM-YYYY"}
                        disabledDate={disabledOriginDate}
                        onChange={(date, dateString) =>
                          onChangeOriginDate(date, dateString)
                        }
                        ref={dateBox}
                        open={showDate}
                        onOpenChange={() => {
                          setShowDate((prev) => !prev);
                        }}
                        placeholder="Departure Date"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={3} xs={24}>
                    <Form.Item
                      className="returnDate"
                      name="arrival"
                      rules={[
                        {
                          required: travel_type === "roundTrip" ? true : false,
                          message: "Please Select a Date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        className="train-search-btn"
                        size="large"
                        format={"DD-MM-YYYY"}
                        defaultPickerValue={arrivalDate}
                        disabledDate={disabledDestDate}
                        onChange={(date) => {
                          if (!date) {
                            form.setFieldsValue({
                              arrival: "",
                            });
                            setTravelType("oneWay");
                          }
                        }}
                        ref={returnDateBox}
                        open={showReturnDate}
                        onOpenChange={() => {
                          setShowReturnDate((prev) => !prev);
                        }}
                        placeholder="Arrival Date"
                        disabled={travel_type != "roundTrip" ? true : false}
                        onClick={() => {
                          if (travel_type != "roundTrip") {
                            setTravelType("roundTrip");
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <MultiCityList setTogle={setTogle} />
              )}

              <Col md={3} xs={24}>
                <Form.Item
                  name="cabinClass"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select size="large">
                    <Option value="Economy">Economy</Option>
                    <Option value="premiumEconomy">Premium Economy</Option>
                    <Option value="Business">Business</Option>
                    <Option value="first">First Class</Option>
                    <Option value="premiumFirst">Premium First Class</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={2} xs={24}>
                <PaxInput />
              </Col>
              <Col md={3} xs={24}>
                <Button
                  ref={searchBtn}
                  className="primary-btn"
                  htmlType="submit"
                >
                  <SearchOutlined />
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FlightSearch;
