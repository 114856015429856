import React, { useState, useEffect } from "react";
import { Col, Row, Layout } from "antd";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AcivitiesSearchForm from "./AcivitiesSearchForm";
import "./Activitiessearch.scss";
import moment from "moment";
import queryString from "query-string";
import ApiClient from "../../../helpers/ApiClient";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import TopCities from "../../../components/home/TopCities/TopCities";
import Reviews from "../../../common/Reviews/Reviews";
import SeoMetaData from "../../../common/Seo/SeoMetaData";

const { Content } = Layout;
const BASE = process.env.REACT_APP_BASE_URL;

const MobileSlidersettings = {
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const ActivitiesSearch = () => {
  const { activityBanners } = useSytContext();
  const [cityActivitiesData, setCityActivitiesData] = useState({
    sliderData: [],
    cityData: [],
  });

  const getCityActivitiesList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 1
          );
          let result = data.reduce(function (obj, key) {
            obj[key.CountryName] = obj[key.CountryName] || [];
            obj[key.CountryName].push(key);
            return obj;
          }, {});
          setCityActivitiesData({ sliderData: data, cityData: result });
        } else {
          setCityActivitiesData({ sliderData: [], cityData: [] });
        }
      })
      .catch((e) => {
        setCityActivitiesData({ sliderData: [], cityData: [] });
      });
  };

  useEffect(() => {
    getCityActivitiesList();
  }, []);

  const getActivitiesUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      params.travelDate = moment().add(1, "days").format("YYYY-MM-DD");
      params = queryString.stringify(params);
      return `/activities/tourDetail?${params}`;
    } else {
      return "";
    }
  };

  return (
    <>
    <SeoMetaData path={window.location.pathname} />
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={activityBanners} />
        </div>
        <div className="bus-search-header">
          <h2>Activities & Things To Do Around the World</h2>
        </div>
        <div className="buses-search">
          <AcivitiesSearchForm />
        </div>
      </div>

      <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts.png").default
                  }
                  alt="activities images"
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  {/* <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p> */}
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts1.png").default
                  }
                  alt="activities images"
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  {/* <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.
                  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {Object.keys(cityActivitiesData.cityData).length > 0
        ? Object.keys(cityActivitiesData.cityData).map((countryName, idx) => (
            <div className="promo providerapi " key={idx}>
              <Layout className="holidays-search-apps activities-home-page">
                <div className="">
                  <Content className="admin-container cms-pages-width text-center">
                    <h4 className="popular-clr-name">
                      Popular Destination In {countryName}
                    </h4>
                    <Row gutter={[12, 12]}>
                      {cityActivitiesData.cityData[countryName].map(
                        (item, i) => (
                          <Col md={6} xs={24} key={i}>
                            <Link to={getActivitiesUrl(item.SearchURL)}>
                              <img
                                className="cart-shopping-img-1"
                                src={
                                  item.HotelImage1
                                    ? `${BASE}${item.HotelImage1.substring(1)}`
                                    : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                        .default
                                }
                                alt={item.CityName}
                              />
                            </Link>
                            <p className="text-center font-weight-bold">
                              {item.HotelName}
                            </p>
                          </Col>
                        )
                      )}
                    </Row>
                  </Content>
                </div>
              </Layout>
            </div>
          ))
        : null}

      <div className="promo providerapi activities-slider">
        <Layout className="holidays-search-apps activities-home-page">
          <Content className="admin-container cms-pages-width text-center">
            <h4 className="popular-clr-name">Top Activities</h4>
            <Slider {...MobileSlidersettings}>
              {cityActivitiesData.sliderData.map((item) => (
                <div key={item.CityHotelID}>
                  <Link to={getActivitiesUrl(item.SearchURL)}>
                    <img
                      className="cart-shopping-img-1"
                      src={
                        item.HotelImage1
                          ? `${BASE}${item.HotelImage1.substring(1)}`
                          : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                              .default
                      }
                      alt={item.CityName}
                    />
                  </Link>
                  <p className="text-center font-weight-bold">
                    {item.HotelName}
                  </p>
                </div>
              ))}
            </Slider>
          </Content>
        </Layout>
      </div>
      <section className="top-route-bb">
        <TopCities />
      </section>
      <section className="top-route-bb">
        <Reviews serviceType={4} />
      </section>
    </>
  );
};

export default ActivitiesSearch;
