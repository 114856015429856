import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import SeoMetaData from "../../common/Seo/SeoMetaData";
const Testimonials = () => {
  return (
    <>
      <CmsPages cmsId={3} pageTitle="Testimonials" />;
      <SeoMetaData path={window.location.pathname} />
    </>
  )
};
export default Testimonials;
