import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import InvoiceBankLogo from "../../../assets/images/invoicebanklogo.jpeg";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    padding: "0 8px",
    color: "#555",
    background: "#FFF",
  },
  header: {
    padding: "7px 0",
    marginBottom: "10px",
  },
  company: { width: "100%", marginBottom: "20px" },
  company_h: {
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  company_p: {
    textAlign: "right",
    fontSize: "8px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  invoice_h: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    margin: 0,
  },
  invoice: {
    width: "100%",
  },
  invoice_origin_h: {
    fontSize: "9px",
    fontWeight: "normal",
    width: "100%",
    marginBottom: 10,
    color: "#E32025",
  },
  origin: {
    width: "40%",
  },
  origin1: {
    width: "60%",
  },
  origin_title: { width: "30%", padding: "3px 6px" },
  origin_h: {
    fontSize: "8px",
    fontWeight: "bold",
  },
  origin_p: {
    fontSize: "8px",
    fontWeight: "normal",
  },
  origin_title1: { width: "40%", padding: "3px 6px" },
  origin_deatil1: { width: "50%", padding: "3px 6px" },
  table_p: {
    fontSize: "8px",
    fontWeight: "normal",
    marginBottom: 0,
  },
  origin_deatil: { width: "50%", padding: "3px 6px" },
  origin_deatil_right: { width: "50%" },
  origin_title_right: { width: "50%" },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 6,
    flexWrap: "wrap",
  },

  table_head: {
    width: "100%",
    color: "#000",
    border: "1px solid #000",
  },
  table_des: {
    width: "30%",
    //  borderRight: "1px solid black",
    padding: "0 3px",
  },
  table_pnr: {
    width: "10%",
    borderRight: "1px solid black",

    // padding: "0 3px",
  },
  table_pnr1: {
    width: "20%",
    borderRight: "1px solid black",

    // padding: "0 3px",
  },

  borderBottom: { borderBottom: "1px solid black" },
  table_p: {
    padding: "2px",
    fontSize: "7px",
    fontWeight: "bolder",
    color: "#000",
  },
  table_p1: {
    padding: "2px",
    fontSize: "7px",
    color: "#525151",
  },
  table_sub_index: {
    width: "10%",

    padding: "0 3px",
  },

  text_right: {
    textAlign: "right",
  },
  table_sub_wrapper: {},

  sub_table1: { marginTop: 10 },
  sub_table: {
    border: "1px solid black",
    width: "60%",
    marginLeft: "auto",
  },

  m_0: {
    margin: 0,
  },
  p_0: {
    padding: 0,
  },
  sub_table_ph: {
    width: "60%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 6px",
    borderRight: "1px solid black",
  },
  sub_table_pt: {
    width: "40%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 10px",
  },
  imgWrapper: {
    width: "100%",
    // justifyContent: "center",
    alignItems: "center",
  },
  invoicecont: {
    padding: "12px",
    border: "1px solid rgb(240,236,236)",
    borderRadius: "3px",
  },
  taxinvoicehead: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    fontWeight: "700",
    fontSize: "14px",
    marginBottom: "15px",
  },
  companyandagentaddlogo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "8px",
  },
  companyadddet: {
    padding: "15px",
    border: "1px solid rgb(236,233,233)",
    borderRadius: "5px",
    width: "49%",
  },
  agentadddet: {
    padding: "15px",
    border: "1px solid rgb(239, 174, 141)",
    backgroundColor: "rgb(239, 234, 231)",
    borderRadius: "5px",
    width: "49%",
  },
  imgWrapper2: {
    margin: "10px 0px",
  },
  companynametext: {
    fontSize: "8px",
    marginBottom: "8px",
    fontWeight: "500",
    letterSpacing: 1.2,
  },
  companyauthortext: {
    fontSize: "8px",
    marginBottom: "4px",
  },
  companyaddresstext: {
    fontSize: "8px",
    marginBottom: "4px",
  },
  companytrnnotext: {
    fontSize: "8px",
    marginBottom: "2px",
  },

  agentlogoscont: {
    display: "flex",
    flexDirection: "row",
    //  marginBottom:"12px"
  },
  agentlogo1imgWrapper: {
    margin: "10px 10px 10px 0px",
  },
  agentlogo2imgWrapper: {
    margin: "10px 10px 10px 0px",
  },
  agentnametext: {
    marginBottom: "8px",
    fontWeight: "700",
    fontSize: "10px",
  },
  agentaddresstext: {
    fontSize: "8px",
    marginBottom: "6px",
  },
  agenttrnnotext: {
    fontSize: "8px",
    marginBottom: "2px",
  },

  // bookingdetailstable
  bookingdettable: {
    marginBottom: "8px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "rgb(236,233,233)",
    borderRadius: "5px",
    width: "100%",
  },
  tablerow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(236,233,233)",
  },
  cell: {
    flex: 1, // Distributes space evenly
    padding: 8,
    borderRightWidth: 1,
    borderRightColor: "rgb(236,233,233)",
    fontSize: "8px",
  },
  tableheader: {
    backgroundColor: "#f0f0f0",
    fontWeight: 900,
    fontSize: "10px",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  lastCell: {
    borderRightWidth: 0, // Remove border for the last cell
  },
  theadorange: {
    color: "rgb(225, 116, 62)",
    // fontWeight:"extrabold"
  },
  servicetypetext: {
    color: "rgb(255, 72, 0)",
    fontWeight: 900,
    padding: "10px 12px",
    width: "100%",
    backgroundColor: "rgb(237, 224, 225)",
    borderRadius: "8px",
    fontSize: "10px",
    marginBottom: "8px",
  },

  // tourtraveltable
  tourtraveltable: {
    marginBottom: "8px",
  },
  tourtableheadrow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "rgb(236,233,233)",
  },
  tourtableheader: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  tourtablemrgn: {
    marginBottom: "8px",
  },

  // noteamount
  noteandamount: {
    backgroundColor: "rgb(240, 240, 240)",
    padding: "12px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "5px",
    marginBottom: "12px",
  },
  notetext1: {
    marginBottom: "8px",
  },
  notetext2: {
    color: "rgb(244, 69, 30)",
  },
  notecont: {
    fontSize: "8px",
  },
  amountdetcont: {
    // textAlign:"right",
    display: "flex",
    alignItems: "flex-end",
    backgroundColor: "#fff",
    padding: "10px 10px 10px 30px",
    fontSize: "10px",
    borderRadius: "5px",
  },
  amtamounttext: {
    marginBottom: "6px",
    textAlign: "right",
    display: "flex",
    flexDirection: "row-reverse",
  },
  amtnetamounttext: {
    textAlign: "right",
    fontWeight: "bold",
  },

  // bankdetails
  bankdetailscont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(236,233,233)",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "12px",
  },
  bankdetdata: {
    flex: 4,
  },
  bankdetimg: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginLeft:"20px"
  },
  banknametext: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "rgb(162, 108, 57)",
    marginBottom: "10px",
  },
  banknamedtltext: {
    fontSize: "9px",
    lineHeight: 1.4,
    marginBottom: "10px",
  },

  // terms and cond
  termconditioncont: {
    // break: "page",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid rgb(236,233,233)",
  },
  termcondhead: {
    color: "rgb(255,72,0)",
    fontWeight: "bold",
    fontSize: "9px",
  },
  termcondtext: {
    fontSize: "7px",
    lineHeight: 1.7,
  },
  link: {
    color: "blue",
  },
});

const getTotalPrice = (data) => {
  var Total = data.subTotal;
  if (data?.convienenceData?.amount) {
    if (data.convienenceData.type === 1) {
      Total += data.convienenceData.amount * data.passengers.length;
    } else if (data.convienenceData.type === 0) {
      Total += (Total / 100) * data.convienenceData.amount;
    }
  }
  return Total;
};
const getTax = (data) => {
  var Total = data.totalPrice;
  var subTotal = data.subTotal;
  var tax = Total - subTotal;
  return tax;
};

const FlightInvoiceDoc = ({ invoiceData, logo, type }) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.invoicecont}>
          <View style={styles.taxinvoicehead}>
            <Text style={styles.taxinvoicetext}>Tax Invoice</Text>
          </View>
          <View style={styles.companyandagentaddlogo}>
            <View style={styles.companyadddet}>
              <View style={styles.imgWrapper2}>
                {logo ? (
                  <Image
                    style={{ width: "80px", height: "40px" }}
                    src={process.env.REACT_APP_BASE_URL + logo.substring(1)}
                  />
                ) : (
                  <Image style={{ width: "80px" }} src={LogoImg} />
                )}
              </View>
              <Text style={styles.agentnametext}>
                {invoiceData?.agentDetails?.AgencyName}{" "}
              </Text>
              <Text style={styles.companyaddresstext}>
                {invoiceData?.agentDetails?.Address1}
                {","}
                {invoiceData?.agentDetails?.Address2}{" "}
              </Text>
              <Text style={styles.companyaddresstext}>
                Phone No. : {invoiceData?.agentDetails?.Phone}{" "}
              </Text>
              <Text style={styles.companytrnnotext}>GST Number :</Text>
            </View>
            <View style={styles.agentadddet}>
              <View style={styles.agentlogoscont}>
                <View style={styles.agentlogo1imgWrapper}>
                  {logo ? (
                    <Image
                      style={{ width: "70px" }}
                      //  src={process.env.REACT_APP_BASE_URL + logo.substring(1)}
                      src={LogoImg}
                    />
                  ) : (
                    <Image style={{ width: "70px" }} src={LogoImg} />
                  )}
                </View>
              </View>
              <Text style={styles.agentnametext}>Shop Your Trip</Text>
              <Text style={styles.agentaddresstext}>
                {" "}
                E-47/7, 1st Floor, Pocket-D, Okhla Phase-2, New Delhi, India,
                110020
              </Text>
              <Text style={styles.agenttrnnotext}>
                {" "}
                GST Number : 100026690600003
              </Text>
            </View>
          </View>

          {/*  booking details */}
          <View style={styles.bookingdettable}>
            <View style={styles.table}>
              {/* Header Row */}
              <View style={[styles.tablerow, styles.tableheader]}>
                <Text>Booking Details</Text>
                <Text style={styles.theadorange}>
                  {" "}
                  Booking Date :{" "}
                  {moment(invoiceData?.leadPassenger?.bookingDate).format(
                    "DD/MM/YY"
                  )}
                </Text>
              </View>

              {/* Data Rows */}
              <View style={styles.tablerow}>
                <Text style={styles.cell}>
                  {" "}
                  Invoice No. : &nbsp;&nbsp; {invoiceData?.invoiceNo}
                </Text>
                <Text style={[styles.cell, styles.lastCell]}>
                  {" "}
                  Guest Name : &nbsp;&nbsp; {
                    invoiceData?.leadPassenger?.prefix
                  }{" "}
                  {invoiceData?.leadPassenger?.firstName}{" "}
                  {invoiceData?.leadPassenger?.lastName}
                </Text>
              </View>
              <View style={styles.tablerow}>
                <Text style={styles.cell}>
                  {" "}
                  Email Id : &nbsp;&nbsp; {invoiceData?.leadPassenger?.email}
                </Text>
                <Text style={[styles.cell, styles.lastCell]}>
                  {" "}
                  Mobile No. : &nbsp;&nbsp; +91
                  {invoiceData?.leadPassenger?.mobile}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.servicetypetext}>
            <Text>Service Type - Tickets & Excursion</Text>
          </View>

          {/* tourtraveltable */}
          <View style={[styles.table, styles.tourtablemrgn]}>
            {/* Header Row */}
            <View style={[styles.tourtableheadrow, styles.tourtableheader]}>
              <Text style={styles.cell}>Tour Name</Text>
              <Text style={styles.cell}>Travel Date</Text>
              <Text style={styles.cell}>Time Slot</Text>
              <Text style={styles.cell}>No. of Pax</Text>
              <Text style={styles.cell}>Booking ID </Text>
              <Text style={[styles.cell, styles.lastCell]}>Total Amount</Text>
            </View>

            {/* Dynamic Data Rows */}
            {/* Dynamic Data Rows */}
            {invoiceData?.activitiesPackageDetails?.map((row, rowIndex) =>
              row?.optionData?.map(
                (
                  option,
                  optionIndex // Loop through all tour options
                ) => (
                  <View
                    key={`${rowIndex}-${optionIndex}`}
                    style={styles.tablerow}
                  >
                    <Text style={[styles.cell]}>{row?.tourName}</Text>
                    <Text style={[styles.cell]}>
                      {option?.tourDate
                        ? `${moment(option?.tourDate).format(
                            "DD/MM/YY"
                          )} (${moment(option?.tourDate).format("dddd")})`
                        : null}
                    </Text>
                    <Text style={[styles.cell]}>{option?.startTime}</Text>
                    <Text style={[styles.cell]}>
                      {option?.adult} Adult, {option?.child} Children,{" "}
                      {option?.infant} Infant
                    </Text>
                    <Text style={[styles.cell]}>{row?.BookingRefNo}</Text>
                    <Text style={[styles.cell, styles.lastCell]}>
                      {option?.serviceTotal}
                    </Text>
                  </View>
                )
              )
            )}
          </View>

          {/* noteandamount */}
          <View style={styles.noteandamount}>
            <View style={styles.notecont}>
              <Text style={styles.notetext1}>
                {" "}
                Note : All prices are inclusive of 18% GST
              </Text>
              <Text style={styles.notetext2}>
                {" "}
                "GST claim on this tax invoice can only be claimed after the
                payment of the particular invoice."
              </Text>
            </View>
            <View style={styles.amountdetcont}>
              <Text style={styles.amtamounttext}>
                Total Amount :{" "}
                {invoiceData?.activitiesPackageDetails
                  ?.reduce((total, row) => {
                    return (
                      total +
                      (row?.optionData?.reduce(
                        (sum, option) =>
                          sum + parseFloat(option?.serviceTotal || 0),
                        0
                      ) || 0)
                    );
                  }, 0)
                  .toFixed(2)}
              </Text>
              <Text style={styles.amtamounttext}>
              Redeemed R Points :{" "}
                {invoiceData?.activitiesPackageDetails
                  ?.reduce((total, row) => {
                    return (
                      total +
                      (row?.optionData?.reduce(
                        (sum, option) =>
                          sum + parseFloat(option?.redeemPoints || 0),
                        0
                      ) || 0)
                    );
                  }, 0)
                  .toFixed(2)}
              </Text>
              <Text style={styles.amtamounttext}>
              Coupon Discount :{" "}
                {invoiceData?.activitiesPackageDetails
                  ?.reduce((total, row) => {
                    return (
                      total +
                      (row?.optionData?.reduce(
                        (sum, option) =>
                          sum + parseFloat(option?.couponAmount || 0),
                        0
                      ) || 0)
                    );
                  }, 0)
                  .toFixed(2)}
              </Text>
              {invoiceData?.partPayment?.isPartPayment === true ? (
                  <>
                    <Text style={styles.amtamounttext}>
                        Paid Amount :{" "}
                          {invoiceData?.partPayment?.partPaymentAmount}{" "}
                    </Text>
                    <Text style={styles.amtamounttext}>
                        Due Amount :{" "}
                        {invoiceData?.partPayment?.dueAmount}
                      </Text>
                      <Text style={styles.amtamounttext}>
                        Last Date :{" "}
                        {invoiceData?.partPayment?.PaymentDueDate}
                     </Text>
                  </>
                ) : null}
              <Text style={styles.amtnetamounttext}>
                Final Amount :{" "}
                {invoiceData?.partPayment?.serviceTotal.toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={styles.bankdetailscont}>
            <View style={styles.bankdetdata}>
              {invoiceData.bankDetails.map(
                (bank, index) =>
                  bank && (
                    <React.Fragment key={index}>
                      <Text style={styles.banknametext}>{bank?.BankName}</Text>
                      <Text style={styles.banknamedtltext}>
                        ACCOUNTS NAME : {bank?.BankName}, AC NO. :{" "}
                        {bank?.AccountNumber}, IFSC NUMBER : {bank?.IFSCNumber},
                        Account Type : {bank?.Comment}, BRANCH NAME :{" "}
                        {bank?.BranchName},&nbsp;
                        {bank?.CountryID?.Country
                          ? `COUNTRY NAME : ${bank?.CountryID?.Country}`
                          : ""}{" "}
                        &nbsp;
                        {bank?.StateID?.State
                          ? `STATE NAME : ${bank?.StateID?.State}`
                          : ""}
                      </Text>
                    </React.Fragment>
                  )
              )}
            </View>
            <View style={styles.bankdetimg}>
              <Image style={{ width: "100px" }} src={InvoiceBankLogo} />
            </View>
          </View>

          {/* terms and conditions */}
          {/* <View style={{ break: "page" }}> */}
          <View style={styles.termconditioncont}>
            <Text style={styles.termcondhead}>Terms & Conditions</Text>
            <Text style={styles.termcondtext}>
              All bookings made with Shop Your Trip are subject to our terms and
              conditions. As these conditions will be binding once the agreement
              takes effect, we strongly advise you to read and understand them
              carefully. Shop Your Trip reserves the right to modify, add, or
              remove any terms and conditions without prior notice. Such changes
              will automatically apply to you upon booking a tour or package
              with us. Failure to comply with our terms and conditions may
              result in the termination of services and the deactivation of your
              account on{" "}
              <Link src="https://shopyourtrip.com/" style={styles.link}>
                {" "}
                www.shopyourtrip.com
              </Link>
            </Text>
          </View>
          {/* </View> */}
        </View>
      </Page>
    </Document>
  );
};

export default FlightInvoiceDoc;





// <View style={styles.section}>
//     <View style={styles.header}>
//         <View style={styles.company}>
//             <View style={styles.imgWrapper}>
//                 {logo ? (
//                     <Image
//                         style={{ width: "70px" }}
//                         src={process.env.REACT_APP_BASE_URL + logo.substring(1)}
//                     />
//                 ) : (
//                     <Image style={{ width: "70px" }} src={LogoImg} />
//                 )}
//                 {invoiceData?.agentDetails ?
//                     <Text style={styles.company_h} class="name">
//                         {invoiceData?.agentDetails?.Address1 + " " + invoiceData?.agentDetails?.Address2 + " " + invoiceData?.agentDetails?.City + " " + invoiceData?.agentDetails?.State}
//                     </Text>
//                     : <Text style={styles.company_h} class="name">
//                         {invoiceData?.adminDetails[0]?.Address ?? ""}
//                     </Text>}
//             </View>
//         </View>

//         <View style={styles.invoice_origin}>
//             <Text style={styles.invoice_origin_h}>
//                 {type === "credit_note"
//                     ? "Credit Note Date :"
//                     : "Invoice Date :"}
//                 {invoiceData?.leadPassenger?.bookingDate
//                     ? moment(invoiceData?.leadPassenger?.bookingDate).format(
//                         "DD/MM/YYYY"
//                     )
//                     : null}
//             </Text>
//         </View>
//         <View style={{ ...styles.origin_wrapper, ...styles.row }}>
//             <View style={{ ...styles.origin, ...styles.row }}>
//                 <View style={styles.origin_title}>
//                     <Text style={styles.origin_h}>To M/s </Text>
//                 </View>
//                 <View style={styles.origin_deatil}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.leadPassenger?.firstName ?? ""}
//                     </Text>
//                 </View>
//                 {invoiceData?.cinNo ? (
//                     <>
//                         <View style={styles.origin_title}>
//                             <Text style={styles.origin_h}>CIN No. </Text>
//                         </View>
//                         <View style={styles.origin_deatil}>
//                             <Text style={styles.origin_p}>
//                                 : {invoiceData?.cinNo ?? ""}
//                             </Text>
//                         </View>
//                     </>
//                 ) : null}

//                 <View style={styles.origin_title}>
//                     <Text style={styles.origin_h}>Client Address </Text>
//                 </View>
//                 <View style={styles.origin_deatil}>
//                     <Text style={styles.origin_p}>
//                         :{" "}
//                         {invoiceData?.adminDetails?.length > 0
//                             ? invoiceData?.adminDetails[0]?.Address
//                             : ""}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title}>
//                     <Text style={styles.origin_h}>Client City </Text>
//                 </View>
//                 <View style={styles.origin_deatil}>
//                     <Text style={styles.origin_p}>
//                         :{" "}
//                         {invoiceData?.adminDetails?.length > 0
//                             ? invoiceData?.adminDetails[0]?.CityName
//                             : ""}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title}>
//                     <Text style={styles.origin_h}>Country </Text>
//                 </View>
//                 <View style={styles.origin_deatil}>
//                     <Text style={styles.origin_p}>
//                         :{" "}
//                         {invoiceData?.adminDetails?.length > 0
//                             ? invoiceData?.adminDetails[0]?.CountryName
//                             : ""}
//                     </Text>
//                 </View>
//             </View>
//             <View style={{ ...styles.origin, ...styles.row }}>
//                 <View style={styles.origin_title_right}>
//                     <Text style={styles.origin_h}>Reference No.</Text>
//                 </View>
//                 <View style={styles.origin_deatil_right}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.bookingRefNo ?? ""}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title_right}>
//                     <Text style={styles.origin_h}>
//                         {type === "credit_note"
//                             ? "Credit Note No. "
//                             : "Invoice No."}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_deatil_right}>
//                     <Text style={styles.origin_p}>
//                         :{" "}
//                         {type === "credit_note"
//                             ? invoiceData?.leadPassenger?.creditNoteNo
//                             : invoiceData?.leadPassenger?.invoiceNo}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title_right}>
//                     <Text style={styles.origin_h}>Email </Text>
//                 </View>
//                 <View style={styles.origin_deatil_right}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.leadPassenger?.email}
//                     </Text>
//                 </View>

//                 <View style={styles.origin_title_right}>
//                     <Text style={styles.origin_h}>Pan No.</Text>
//                 </View>
//                 <View style={styles.origin_deatil_right}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.GSTDetails?.PANNumber ?? ""}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title_right}>
//                     <Text style={styles.origin_h}>GST No. </Text>
//                 </View>
//                 <View style={styles.origin_deatil_right}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.GSTDetails?.GSTNumber ?? ""}
//                     </Text>
//                 </View>
//             </View>
//         </View>
//         <View style={{ ...styles.origin_wrapper, ...styles.row }}>
//             <View style={{ ...styles.origin1, ...styles.row }}>
//                 <View style={styles.origin_title1}>
//                     <Text style={styles.origin_h}>Client Name </Text>
//                 </View>
//                 <View style={styles.origin_deatil1}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.adminDetails?.CompanyName}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title1}>
//                     <Text style={styles.origin_h}>Client Email </Text>
//                 </View>
//                 <View style={styles.origin_deatil1}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.adminDetails?.Email}
//                     </Text>
//                 </View>
//                 <View style={styles.origin_title1}>
//                     <Text style={styles.origin_h}>GSTIN No. </Text>
//                 </View>
//                 <View style={styles.origin_deatil1}>
//                     <Text style={styles.origin_p}>
//                         : {invoiceData?.adminDetails?.GSTINNumber}
//                     </Text>
//                 </View>
//             </View>
//         </View>

//         <View style={styles.table_head}>
//             <View style={{ ...styles.row, ...styles.m_0, ...styles.borderBottom }}>
//                 <View style={styles.table_pnr}>
//                     <Text style={styles.table_p}>Pax Name</Text>
//                 </View>

//                 <View style={styles.table_pnr}>
//                     <Text style={styles.table_p}>Booking Date</Text>
//                 </View>
//                 <View style={styles.table_pnr1}>
//                     <Text style={styles.table_p}>Booking Id.</Text>
//                 </View>
//                 <View style={styles.table_pnr1}>
//                     <Text style={styles.table_p}>Activity Name</Text>
//                 </View>
//                 <View style={styles.table_pnr1}>
//                     <Text style={styles.table_p}>Hotel Name</Text>
//                 </View>
//                 <View style={styles.table_pnr1}>
//                     <Text style={styles.table_p}>City Name</Text>
//                 </View>
//             </View>
//             {invoiceData?.leadPassenger ? (
//                 <View
//                     style={{
//                         ...styles.row,
//                         ...styles.m_0,
//                         ...styles.borderBottom,
//                     }}
//                 >
//                     <View style={styles.table_pnr}>
//                         <Text style={styles.table_p1}>
//                             {invoiceData?.leadPassenger?.firstName}
//                         </Text>
//                     </View>

//                     <View style={styles.table_pnr}>
//                         <Text style={styles.table_p1}>
//                             {moment(invoiceData?.leadPassenger?.travelDate).format(
//                                 "MMM DD,YYYY"
//                             )}
//                         </Text>
//                     </View>
//                     <View style={styles.table_pnr1}>
//                         <Text style={styles.table_p1}>
//                             {invoiceData?.bookingRefNo ?? ""}
//                         </Text>
//                     </View>
//                     <View style={styles.table_pnr1}>
//                         <Text style={styles.table_p1}>
//                             {invoiceData?.activitiesPackageDetails?.length > 0
//                                 ? invoiceData?.activitiesPackageDetails[0]?.tourName
//                                 : ""}
//                         </Text>
//                     </View>
//                     <View style={styles.table_pnr1}>
//                         <Text style={styles.table_p1}>
//                             {invoiceData?.hotelsPackageDetails?.length > 0
//                                 ? invoiceData?.hotelsPackageDetails[0]?.HotelName
//                                 : ""}
//                         </Text>
//                     </View>

//                     <View style={styles.table_pnr1}>
//                         <Text style={styles.table_p1}>
//                             {invoiceData?.request?.city ?? ""}
//                         </Text>
//                     </View>
//                     <View style={styles.table_pnr1}>
//                         <Text style={styles.table_p1}>
//                             {" "}
//                             {invoiceData?.ConfirmationNumber}
//                         </Text>
//                     </View>
//                 </View>
//             ) : null}
//         </View>

//         <View style={styles.sub_table1}>
//             <View style={styles.sub_table}>
//                 <View
//                     style={{
//                         ...styles.sub_table_details1,
//                         ...styles.row,
//                         marginBottom: 0,
//                     }}
//                 >
//                     <Text
//                         style={{
//                             ...styles.sub_table_ph,
//                         }}
//                     >
//                         Sub Total
//                     </Text>
//                     <Text style={{ ...styles.sub_table_pt }}>
//                         INR{" "}
//                         {Number(invoiceData?.partPayment?.serviceTotal).toFixed(2)}
//                     </Text>
//                 </View>
//                 <View
//                     style={{
//                         ...styles.sub_table_details1,
//                         ...styles.row,
//                         marginBottom: 0,
//                     }}
//                 >
//                     <View
//                         style={{
//                             ...styles.sub_table_ph,
//                         }}
//                     >
//                         <Text>Tax</Text>
//                     </View>
//                     <Text style={styles.sub_table_pt}>
//                         {/* INR {getTax(invoiceData)} */}
//                         INR 0
//                     </Text>
//                 </View>
//                 <View
//                     style={{
//                         ...styles.sub_table_details1,
//                         ...styles.row,
//                         marginBottom: 0,
//                         borderBottom: "1px solid black",
//                     }}
//                 >
//                     <View
//                         style={{
//                             ...styles.sub_table_ph,
//                         }}
//                     >
//                         <Text>Convenience Fee</Text>
//                     </View>
//                     {invoiceData?.convienenceData?.type === 1 ? (
//                         <Text style={styles.sub_table_pt}>
//                             INR {invoiceData?.convienenceData?.amount ?? 0} X{" "}
//                             {invoiceData?.passengers?.length}
//                         </Text>
//                     ) : (
//                         <Text style={styles.sub_table_pt}>
//                             INR {invoiceData?.convienenceData?.amount ?? 0}
//                         </Text>
//                     )}
//                 </View>

//                 <View
//                     style={{
//                         ...styles.sub_table_details1,
//                         ...styles.row,
//                         marginBottom: 0,
//                     }}
//                 >
//                     <View
//                         style={{
//                             ...styles.sub_table_ph,
//                         }}
//                     >
//                         <Text
//                             style={{
//                                 fontWeight: "800",
//                                 color: "#000",
//                             }}
//                         >
//                             Grand Total
//                         </Text>
//                     </View>
//                     <Text
//                         style={{
//                             ...styles.sub_table_pt,
//                             fontWeight: "800",
//                             color: "#000",
//                         }}
//                     >
//                         {/* INR {getTotalPrice(invoiceData)} */}
//                         INR{" "}
//                         {Number(invoiceData?.partPayment?.serviceTotal).toFixed(2)}
//                     </Text>
//                 </View>
//                 {type === "credit_note" ? (
//                     <View
//                         style={{
//                             ...styles.sub_table_details1,
//                             ...styles.row,
//                             marginBottom: 0,
//                         }}
//                     >
//                         <View
//                             style={{
//                                 ...styles.sub_table_ph,
//                             }}
//                         >
//                             <Text
//                                 style={{
//                                     fontWeight: "800",
//                                     color: "#000",
//                                 }}
//                             >
//                                 Refunded Amount
//                             </Text>
//                         </View>
//                         <Text
//                             style={{
//                                 ...styles.sub_table_pt,
//                                 fontWeight: "800",
//                                 color: "#000",
//                             }}
//                         >
//                             {/* INR {getTotalPrice(invoiceData)} */}
//                             INR {invoiceData?.PartPayment?.refundAmount}
//                         </Text>
//                     </View>
//                 ) : null}
//             </View>
//         </View>
//         <View style={{ marginTop: "20px", width: "100%" }}>
//             <Text style={{ fontSize: "8px", textAlign: "center" }}>
//                 Note : This is electronic generated Invoice
//             </Text>
//         </View>
//     </View>
// </View>
