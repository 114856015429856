import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import APIClient from "../../helpers/ApiClient";
import CountryList from "../../common/CountryList";

import "./Insurance.scss";
import { Helmet } from "react-helmet";
import { AppConstants } from "../../helpers/constants";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const Insurance = () => {
  const { Option } = Select;
  const [form] = Form.useForm();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [travel_type, setTravelType] = useState("singleTrip");
  const validateMessages = {
    required: "",
  };
  const history = useHistory();
  const departureDate = moment();
  const updateTravelType = (val) => {
    if (val) {
      setTravelType(val);
    }
  };
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (value) => {
    let req = {
      ...value,
      TripType: travel_type,
      InsuracneEnquiry: 1,
    };

    APIClient.post("admin/offlineEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          history.push("/Thankyou");
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {});
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  return (
    <>
    <SeoMetaData path={window.location.pathname} />
      <Helmet>
        <title>Travel Insurance - {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="ShopYourTrip offers the best international travel insurance plan for you and all your family members."
        />
      </Helmet>
      <div className="manage-markup-section flight-enquiry-24">
        <div className="container">
          <h5 className="text-center"> Insurance Enquiry</h5>
          <Form
            layout="vertical"
            form={form}
            onFinish={submitForm}
            initialValues={{ ISDCode: "+91" }}
            validateMessages={validateMessages}
          >
            <Card className="cardRounded">
              <Row gutter={24}>
                <Col md={24} sm={24} className="mb-3">
                  <Radio.Group
                    onChange={(e) => updateTravelType(e.target.value)}
                    value={travel_type}
                  >
                    <Radio value={"singleTrip"}>Single Trip</Radio>
                    <Radio value={"multiTrip"}>Annuual Multi Trip</Radio>
                  </Radio.Group>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item
                    label="Name"
                    name="Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[{ required: true }, { type: "email" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={8} xs={24}>
                  <Form.Item
                    label="Contact Number"
                    name="ContactNumber"
                    className="phno"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number  Required",
                      },
                      {
                        minLength: 10,
                        maxLength: 10,
                        pattern: "^[0-9]{10}$",
                        message: "Must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={
                        <Form.Item
                          style={{ width: "35%" }}
                          name="ISDCode"
                          className="phno"
                          rules={[
                            {
                              required: true,
                              message: "Phone Number Code Required",
                            },
                          ]}
                          noStyle
                        >
                          <Select
                            showSearch
                            // placeholder="Select Country"
                            style={{ width: "80px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {CountryList.map((item) => (
                              <Option
                                key={item.dial_code}
                                value={item.dial_code}
                              >
                                {item.dial_code}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                      className="inputbg"
                      placeholder="Enter Mobile No."
                    />
                  </Form.Item>
                </Col>

                <Col md={8} sm={24}>
                  <Form.Item label="Select Destination" name="Destination">
                    <Select>
                      <Option value={"US"}>US</Option>
                      <Option value={"Non US"}>Non US</Option>
                      <Option value={"India"}>India</Option>
                      <Option value={"Asia"}>Asia</Option>
                      <Option value={"Canada"}>Canada</Option>
                      <Option value={"Australia"}>Australia</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8} sm={24}>
                  <Form.Item
                    label="Departure Date"
                    name="DepartureDate"
                    rules={[
                      {
                        required: true,
                        message: "Please Select a Date",
                      },
                    ]}
                  >
                    <DatePicker
                      allowClear={true}
                      style={{ width: "100%" }}
                      className="train-search-btn"
                      size="medium"
                      format={"DD-MM-YYYY"}
                      disabledDate={disabledOriginDate}
                    />
                  </Form.Item>
                </Col>
                {travel_type === "singleTrip" && (
                  <Col md={8} sm={24}>
                    <Form.Item
                      label="Arrival Date"
                      name="ReturnDate"
                      rules={[
                        {
                          required: true,
                          message: "Please Select a Date",
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear={true}
                        style={{ width: "100%" }}
                        className="train-search-btn"
                        size="medium"
                        format={"DD-MM-YYYY"}
                        disabledDate={disabledOriginDate}
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col md={8} sm={24}>
                  <Form.Item
                    label="No. Of Pax"
                    name="NumberOfPassengers"
                    rules={[{ pattern: "^[1-9]+[0-9]*$" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" className="my-3">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
              </Row>
              <Row justify="center">
                <Space size="middle">
                  <Button
                    disabled={buttonDisabled}
                    type="primary"
                    onClick={() => form.submit()}
                  >
                    Submit
                  </Button>
                  <Button type="primary" danger>
                    Cancel
                  </Button>
                </Space>
              </Row>
            </Card>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Insurance;
